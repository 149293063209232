import React, { FC, useCallback } from 'react'
import Box from '@material-ui/core/Box'
import { Hidden, makeStyles, Typography } from '@material-ui/core'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import Button from '@material-ui/core/Button'
import { SectionTitle, SectionContent } from '../Common'
import { PATH_CONNECTIVITY } from '../../constants'

const useStyles = makeStyles((theme) => ({
  teaserContainer: {
    marginLeft: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(0),
      marginBottom: theme.spacing(2),
    },
  },
  buttonContainer: {
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      width: '100%',
    },
  },
  getStartedButton: {
    fontWeight: 700,
    minWidth: 200,
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  small: {
    fontSize: '0.8rem',
    color: 'grey',
  },
}))

export const EntrepreneurAccountsTeaser: FC = () => {
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation()

  const apiHandler = useCallback(() => {
    window.location.href = 'https://dashboard.bivial.com/signup'
  }, [history])

  return (
    <>
      <Box className={classes.teaserContainer}>
        <Hidden mdUp>
          <Box mt={12} />
        </Hidden>
        <SectionTitle title="EntrepreneurAccounts-H2" />
        <SectionContent text="EntrepreneurAccounts-P1" />
        <Box mt={4} mb={6} className={classes.buttonContainer}>
          <Button
            size="medium"
            variant="contained"
            color="primary"
            onClick={apiHandler}
            className={classes.getStartedButton}
          >
            {t('EntrepreneurAccounts-CTA-Get-Started')}
          </Button>
        </Box>
        <Box>
          <Typography className={classes.small}>{t('EntrepreneurAccounts-P2')}</Typography>
        </Box>
      </Box>
    </>
  )
}
