import { FC, default as React } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import CardMedia from '@material-ui/core/CardMedia'
import FastIcon from '../../assets/icons/icon-fast.svg?react'
import PayIcon from '../../assets/icons/icon-pay-where-you-do.svg?react'
import ClarityIcon from '../../assets/icons/icon-clarity.svg?react'
import imgHero2x from '../../assets/icons/map.svg'
import aud from '../../assets/icons/aud.svg'
import cad from '../../assets/icons/cad.svg'
import chf from '../../assets/icons/chf.svg'
import eur from '../../assets/icons/eur.svg'
import gbp from '../../assets/icons/gbp.svg'
import jpy from '../../assets/icons/jpy.svg'
import nok from '../../assets/icons/nok.svg'
import nzd from '../../assets/icons/nzd.svg'
import sek from '../../assets/icons/sek.svg'
import usd from '../../assets/icons/usd.svg'
import Box from '@material-ui/core/Box'
import { Hidden } from '@material-ui/core'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { tagManagerHandler } from '../../utils'

const useStyles = makeStyles((theme) => ({
  heroContent: {
    backgroundColor: theme.palette.background.paper,
  },
  heroContentPref: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
  },
  iPhoneimg: {
    display: 'inline-block',
    width: '70%',
    [theme.breakpoints.down('sm')]: {
      width: '50%',
    },
  },
  icon: {
    justifyContent: 'center',
  },
  readMoreButton: {
    width: 216,
    background: 'black',
    color: 'white',
    fontWeight: 700,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#ef2828',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  footerNavColumns: {
    justifyContent: 'space-between',
  },
  mrgbtm: {
    marginBottom: theme.spacing(12),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(4),
    },
  },
  footerNavColumnList: {
    display: 'block',

    MuiListItemButton: {
      padding: 0,
    },
  },
  heroContentPrefHeader: {
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
    },
  },
  subtitleBoxIcon: {
    body1: {
      fontSize: '1.125rem',
      fontWeight: 'normal',
    },
  },
  menuItem: {
    flexWrap: 'wrap',
    padding: theme.spacing(1.5),

    [theme.breakpoints.down('sm')]: {
      marginTop: 50,
    },

    '& .MuiMenuItem-root': {
      width: '50%',
      padding: theme.spacing(2.5),
      justifyContent: 'center',
      whiteSpace: 'normal',
    },

    '& .MuiListItem-button:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
    },

    '& .img:hover': { backgroundColor: theme.palette.secondary.main },

    '& .menuItemInner': {
      display: 'flex',
      alignItems: 'top',
      paddingTop: theme.spacing(0),
      borderTop: '8px solid transparent',
      transition: 'border-top-color 0.2s, font-weight 0.2s',
      position: 'relative',
      top: 0,
    },
    '&:hover': {
      backgroundColor: 'transparent',

      '& .MuiListItemIcon-root': {
        opacity: 1,
      },
    },
    '&:hover .menuItemInner': {
      backgroundColor: 'green',
    },
    '&.Mui-selected': {
      backgroundColor: 'transparent',
      background: theme.palette.secondary.main,
      '& .menuItemInner': {
        background: theme.palette.secondary.main,
      },
      '& .MuiListItemIcon-root': {
        opacity: 1,
        // color: theme.palette.secondary.main,
      },
      '& .MuiTypography-body1': {},
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
  minW: {
    minWidth: '250px',
  },
  imgWrap: {
    textAlign: 'center',
  },
  row: {
    marginTop: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(4),
      textAlign: 'center',
    },
    '& .MuiTypography-h3': {
      marginBottom: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },
  },
  pageTitle: {
    marginTop: theme.spacing(8),
    fontSize: '3rem',
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.75rem',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(6),
      fontSize: '2.25rem',
    },
  },
  pageSubtitle: {
    fontSize: '2.75rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.25rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '2rem',
    },
  },
  rowTitle: {
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
      textAlign: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.5rem',
    },
  },
  rowText: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  extraMargin: {
    marginTop: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(8),
    },
  },
  features: {
    textAlign: 'center',
  },
  buttonContainer: {
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      width: '100%',
    },
  },
  getStartedButton: {
    fontWeight: 700,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  link: {
    textDecoration: 'none',
    '&:hover': {
      opacity: 1,
      textDecoration: 'underline',
    },
  },
  greyBox: {
    backgroundColor: '#fafafa',
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    paddingTop: theme.spacing(1),
    paddingBotoom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(-12),
      marginRight: theme.spacing(-12),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
      paddingBottom: theme.spacing(3),
    },
  },
}))

export const CrossBorderPaymentsPage: FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  tagManagerHandler('PageCrossBorderPayments')

  return (
    <>
      <Container>
        <Box>
          <Grid item xs={12} container justify={'center'}>
            <Typography
              component="h1"
              variant={'h1'}
              align="center"
              color="textPrimary"
              gutterBottom
              className={classes.pageTitle}
            >
              {t('CrossBorderPayments-H1')}
            </Typography>
            <Grid item md={10} spacing={1} xs={12} container justify={'center'}>
              <Typography variant="subtitle1" align="center" component={'div'} gutterBottom>
                {t('CrossBorderPayments-P11')}
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <Box className={classes.row}>
          <Grid container>
            <Hidden smDown>
              <Grid item xs={1}></Grid>
            </Hidden>
            <Grid item xs={12} md={10}>
              <CardMedia component="img" alt="" height="100%" image={imgHero2x} />
            </Grid>
          </Grid>
        </Box>

        <Box className={clsx(classes.greyBox, classes.row)}>
          <Grid item xs={12} container justify={'center'}>
            <Typography
              component="h1"
              variant={'h1'}
              align="center"
              color="textPrimary"
              gutterBottom
              className={clsx(classes.pageTitle, classes.pageSubtitle)}
            >
              {t('CrossBorderPayments-H2')}
            </Typography>
          </Grid>
          <Grid
            container
            spacing={5}
            className={clsx(classes.features, classes.extraMargin)}
            alignContent={'center'}
          >
            <Grid item xs={12} md={4}>
              <Box p={4}>
                <FastIcon />
              </Box>
              <Typography component="h3" variant={'h3'}>
                {t('CrossBorderPayments-H3')}
              </Typography>
              <Typography>{t('CrossBorderPayments-P3')}</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box p={4}>
                <PayIcon />
              </Box>
              <Typography component="h3" variant={'h3'}>
                {t('CrossBorderPayments-H4')}
              </Typography>
              <Typography>{t('CrossBorderPayments-P4')}</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box p={4}>
                <ClarityIcon />
              </Box>
              <Typography component="h3" variant={'h3'}>
                {t('CrossBorderPayments-H5')}
              </Typography>
              <Typography>{t('CrossBorderPayments-P5')}</Typography>
            </Grid>
          </Grid>
        </Box>

        <Box className={classes.row}>
          <Grid item md={8} xs={12} container justify={'center'} style={{ margin: 'auto' }}>
            <Typography
              component="h1"
              variant={'h1'}
              align="center"
              color="textPrimary"
              gutterBottom
              className={classes.pageTitle}
            >
              {t('CrossBorderPayments-H6')}
            </Typography>
          </Grid>
        </Box>

        <Box className={classes.row}>
          <Grid item xs={12} container alignContent={'center'}>
            <Hidden smDown>
              <Grid item xs={1}></Grid>
            </Hidden>
            <Grid item xs={6} md={2} container justifyContent={'center'}>
              <Box p={2}>
                <CardMedia component="img" alt="" image={aud} />
              </Box>
            </Grid>
            <Grid item xs={6} md={2} container justifyContent={'center'}>
              <Box p={2}>
                <CardMedia component="img" alt="" image={cad} />
              </Box>
            </Grid>
            <Grid item xs={6} md={2} container justifyContent={'center'}>
              <Box p={2}>
                <CardMedia component="img" alt="" image={chf} />
              </Box>
            </Grid>
            <Grid item xs={6} md={2} container justifyContent={'center'}>
              <Box p={2}>
                <CardMedia component="img" alt="" image={eur} />
              </Box>
            </Grid>
            <Grid item xs={6} md={2} container justifyContent={'center'}>
              <Box p={2}>
                <CardMedia component="img" alt="" image={gbp} />
              </Box>
            </Grid>
            <Hidden smDown>
              <Grid item xs={1}></Grid>
            </Hidden>
            <Hidden smDown>
              <Grid item xs={1}></Grid>
            </Hidden>
            <Grid item xs={6} md={2} container justifyContent={'center'}>
              <Box p={2}>
                <CardMedia component="img" alt="" image={jpy} />
              </Box>
            </Grid>
            <Grid item xs={6} md={2} container justifyContent={'center'}>
              <Box p={2}>
                <CardMedia component="img" alt="" image={nok} />
              </Box>
            </Grid>
            <Grid item xs={6} md={2} container justifyContent={'center'}>
              <Box p={2}>
                <CardMedia component="img" alt="" image={nzd} />
              </Box>
            </Grid>
            <Grid item xs={6} md={2} container justifyContent={'center'}>
              <Box p={2}>
                <CardMedia component="img" alt="" image={sek} />
              </Box>
            </Grid>
            <Grid item xs={6} md={2} container justifyContent={'center'}>
              <Box p={2}>
                <CardMedia component="img" alt="" image={usd} />
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box className={classes.row}>
          <Grid item xs={12} container justifyContent={'center'}>
            <p>
              {t('CrossBorderPayments-P21')}&nbsp;
              <a className={classes.link} href={'https://dashboard.bivial.com'}>
                {t('CrossBorderPayments-P22')}
              </a>
              .
            </p>
          </Grid>
        </Box>
      </Container>
    </>
  )
}
