const outgoingInternationalWires = {
  type: 'outbound_international_wires',
  sticky_note: '*Restrictions may apply.',
  visible_columns: ['currency', 'region', 'clearing_system'],
  hidden_columns: [],
  column_names: {
    currency: '16',
    region: '04',
    clearing_system: '03',
  },
  data: [
    {
      currency: 'EUR',
      region: 'Worldwide',
      clearing_system: 'SEPA, TARGET2, SWIFT',
    },
    {
      currency: 'CHF',
      region: 'Worldwide',
      clearing_system: 'SIC, SWIFT',
    },
    {
      currency: 'USD',
      region: 'Worldwide',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'GBP',
      region: 'Worldwide',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'JPY',
      region: 'Worldwide',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'DKK',
      region: 'Worldwide',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'SEK',
      region: 'Worldwide',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'NOK',
      region: 'Worldwide',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'TRY',
      region: 'Worldwide',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'AED',
      region: 'Worldwide',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'AUD',
      region: 'Worldwide',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'CAD',
      region: 'Worldwide',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'PLN',
      region: 'Worldwide',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'ZAR',
      region: 'Worldwide',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'HKD',
      region: 'Worldwide',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'HUF',
      region: 'Worldwide',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'MXN',
      region: 'Worldwide',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'ALL',
      region: 'Albania',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'DZD',
      region: 'Algeria',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'AOA',
      region: 'Angola ',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'XCD',
      region: 'Anguilla ',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'XCD',
      region: 'Antigua and Barbuda',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'ARS',
      region: 'Argentina',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'AMD',
      region: 'Armenia',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'AZN',
      region: 'Azerbaijan ',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'BSD',
      region: 'Bahamas',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'BHD',
      region: 'Bahrain',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'BDT',
      region: 'Bangladesh ',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'BBD',
      region: 'Barbados ',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'BZD',
      region: 'Belize ',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'XOF',
      region: 'Benin',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'BMD',
      region: 'Bermuda',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'BOB',
      region: 'Bolivia',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'BAM',
      region: 'Bosnia and Herzegovina ',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'BWP',
      region: 'Botswana ',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'BRL',
      region: 'Brazil ',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'BND',
      region: 'Brunei-Darussalam',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'BGN',
      region: 'Bulgaria ',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'XOF',
      region: 'Burkina Faso ',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'BIF',
      region: 'Burundi',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'KHR',
      region: 'Cambodia ',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'XAF',
      region: 'Cameroon ',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'CVE',
      region: 'Cape Verde ',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'KYD',
      region: 'Cayman Islands ',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'XAF',
      region: 'Central African Republic ',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'XAF',
      region: 'Chad ',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'CLP',
      region: 'Chile',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'CNY',
      region: 'China',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'COP',
      region: 'Colombia ',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'CRC',
      region: 'Costa Rica ',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'HRK',
      region: 'Croatia',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'CZK',
      region: 'Czech Republic (Czechia) ',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'DJF',
      region: 'Djibouti ',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'XCD',
      region: 'Dominica ',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'DOP',
      region: 'Dominican Republic ',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'EGP',
      region: 'Egypt',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'XAF',
      region: 'Equatorial Guinea',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'ERN',
      region: 'Eritrea',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'ETB',
      region: 'Ethiopia ',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'FJD',
      region: 'Fiji ',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'XAF',
      region: 'Gabon',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'GMD',
      region: 'Gambia ',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'GEL',
      region: 'Georgia',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'GHS',
      region: 'Ghana',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'XCD',
      region: 'Grenada',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'GTQ',
      region: 'Guatemala',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'GNF',
      region: 'Guinea Republic',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'XOF',
      region: 'Guinea-Bissau',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'GYD',
      region: 'Guyana ',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'HTG',
      region: 'Haiti',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'HNL',
      region: 'Honduras ',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'ISK',
      region: 'Iceland',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'INR',
      region: 'India',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'IDR',
      region: 'Indonesia',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'IQD',
      region: 'Iraq ',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'ILS',
      region: 'Israel ',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'XOF',
      region: 'Ivory Coast',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'JMD',
      region: 'Jamaica',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'JOD',
      region: 'Jordan ',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'KZT',
      region: 'Kazakhstan ',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'KES',
      region: 'Kenya',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'KWD',
      region: 'Kuwait ',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'KGS',
      region: 'Kyrgyzstan ',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'LAK',
      region: 'Laos ',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'LSL',
      region: 'Lesotho',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'MOP',
      region: 'Macau',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'MGA',
      region: 'Madagascar ',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'MWK',
      region: 'Malawi ',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'MYR',
      region: 'Malaysia ',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'MVR',
      region: 'Maldives ',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'XOF',
      region: 'Mali ',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'MUR',
      region: 'Mauritius',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'MNT',
      region: 'Mongolia ',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'XCD',
      region: 'Montserrat ',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'MAD',
      region: 'Morocco',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'MZN',
      region: 'Mozambique ',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'NAD',
      region: 'Namibia',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'NPR',
      region: 'Nepal',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'ANG',
      region: 'Netherlands Antilles - Curacao ',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'NZD',
      region: 'New Zealand',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'NIO',
      region: 'Nicaragua',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'XOF',
      region: 'Niger',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'NGN',
      region: 'Nigeria',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'OMR',
      region: 'Oman ',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'PKR',
      region: 'Pakistan ',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'PGK',
      region: 'Papua New Guinea ',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'PYG',
      region: 'Paraguay ',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'PEN',
      region: 'Peru ',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'PHP',
      region: 'Philippines',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'QAR',
      region: 'Qatar',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'XAF',
      region: 'Republic of the Congo',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'RON',
      region: 'Romania',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'RWF',
      region: 'Rwanda ',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'XCD',
      region: 'Saint Kitts and Nevis',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'XCD',
      region: 'Saint Lucia',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'XCD',
      region: 'Saint Vincent and the Grenadine',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'WST',
      region: 'Samoa',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'STN',
      region: 'São Tomé and Príncipe',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'SAR',
      region: 'Saudi Arabia ',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'XOF',
      region: 'Senegal',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'RSD',
      region: 'Serbia ',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'SCR',
      region: 'Seychelles ',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'SLL',
      region: 'Sierra Leone ',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'SGD',
      region: 'Singapore',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'SBD',
      region: 'Solomon Islands',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'KRW',
      region: 'South Korea',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'LKR',
      region: 'Sri Lanka',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'SRD',
      region: 'Suriname ',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'SZL',
      region: 'Swaziland (Eswatini) ',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'XPF',
      region: 'Tahiti ',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'TWD',
      region: 'Taiwan ',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'TZS',
      region: 'Tanzania ',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'THB',
      region: 'Thailand ',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'XOF',
      region: 'Togo ',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'TOP',
      region: 'Tonga',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'TTD',
      region: 'Trinidad and Tobago',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'TND',
      region: 'Tunisia',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'UGX',
      region: 'Uganda ',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'UAH',
      region: 'Ukraine',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'VUV',
      region: 'Vanuatu',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'VND',
      region: 'Vietnam',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'ZMW',
      region: 'Zambia ',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'LBP',
      region: 'Lebanon',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'UZS',
      region: 'Uzbekistan ',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'PAB',
      region: '/ USD Panama ',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'UYU',
      region: 'Uruguay',
      clearing_system: 'SWIFT',
    },
  ],
}

const exportedObject = outgoingInternationalWires

export default exportedObject
