import React, { FC, useCallback } from 'react'
import Box from '@material-ui/core/Box'
import { CardMedia, Hidden, makeStyles, Typography } from '@material-ui/core'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import Button from '@material-ui/core/Button'
import { SectionTitle, SectionContent } from '../Common'
import { PATH_CONNECTIVITY } from '../../constants'
import icon1 from '../../assets/images/img-cards-apple-pay@2x.png'
import icon2 from '../../assets/images/img-cards-google-pay@2x.png'

const useStyles = makeStyles((theme) => ({
  teaserContainer: {
    marginLeft: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(0),
    },
  },
  buttonContainer: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      width: '100%',
    },
  },
  getStartedButton: {
    fontWeight: 700,
    minWidth: 200,
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: 350,
      margin: 'auto',
    },
  },
  icon: {
    height: '24px',
    width: 'auto',
    float: 'left',
    marginRight: theme.spacing(5),
    [theme.breakpoints.down('md')]: {
      marginRight: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      float: 'none',
      margin: 'auto',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(4),
    },
  },
}))

export const CardsTeaser: FC = () => {
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation()

  const apiHandler = useCallback(() => {
    window.location.href = 'https://dashboard.bivial.com/signup'
  }, [history])

  return (
    <>
      <Box className={classes.teaserContainer}>
        <Hidden mdUp>
          <Box mt={12} />
        </Hidden>
        <SectionTitle title="Cards-H2" />
        <SectionContent text="Cards-P2" />
        <Box mt={4} mb={6} className={classes.buttonContainer}>
          <Button
            size="medium"
            variant="contained"
            color="primary"
            onClick={apiHandler}
            className={classes.getStartedButton}
          >
            {t('Cards-CTA-Get-Started')}
          </Button>
        </Box>
        <Box>
          <Typography>
            <Hidden smDown>
              <b className={classes.icon}>{t('Cards-P21')}</b>
              <CardMedia component="img" alt="" image={icon2} className={classes.icon} />
              <CardMedia component="img" alt="" image={icon1} className={classes.icon} />
            </Hidden>
            <Hidden mdUp>
              <Box textAlign={'center'}>
                <b>{t('Cards-P21')}</b>
                <br />
                <CardMedia component="img" alt="" image={icon2} className={classes.icon} />
                <CardMedia component="img" alt="" image={icon1} className={classes.icon} />
              </Box>
            </Hidden>
          </Typography>
        </Box>
      </Box>
    </>
  )
}
