import React, { FC, useCallback } from 'react'
import { useForm, FormProvider, FormState } from 'react-hook-form'
import clsx from 'clsx'
import { isEmpty } from 'lodash'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'

import { yupResolver } from '@hookform/resolvers/yup'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'

import { toast, ToastContainer } from 'react-toastify'
import { emailEnquiryTypes } from '@klarpay/enums'
import i18n from 'i18next'

import imgHero2x from '../../assets/images/img-talk-to-us.png'
import IconArrow from '../../assets/icons/icon-arrow.svg?react'
import { rxios } from '../../resources'
import { TalkToUsSchema } from '../../schemes/TalkToUsSchema'

import {
  WebsiteTitle,
  FeatureBox,
  FormControlledTextField,
  CountryPhoneSelect,
  FormAutocompleteSelect,
  SubSubFooter,
} from '../Common'
import { tagManagerHandler } from '../../utils'
import { emailEnquiryArray, PATH_MESSAGE_SENT } from '../../constants'
import { Button, CardMedia, FormControl, Hidden, Typography } from '@material-ui/core'
import { text } from 'stream/consumers'

const useStyles = makeStyles((theme) => ({
  paddingBox: {
    padding: theme.spacing(2.5, 5),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1.5, 2),
    },
  },
  featureContainer: {
    maxWidth: '1100px',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '400px',
    },
  },
  formControl: {
    width: '100%',
    height: theme.spacing(11.5),
    marginRight: theme.spacing(2),
    '& .MuiFormControl-root': {
      marginTop: 0,
    },
    '& .MuiInputLabel-shrink': {
      display: 'none',
    },
  },
  longField: {
    maxWidth: 673,
    marginRight: theme.spacing(4),
  },
  shortField: {
    maxWidth: 320,
    marginRight: theme.spacing(4),
  },
  block: {
    '&.MuiGrid-item': {
      padding: theme.spacing(4, 0, 4, 1.5),
    },
  },
  contactList: {
    flexDirection: 'column',
    backgroundColor: '#f6f6f6',
    padding: theme.spacing(6, 6, 6, 6),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(6, 4),
    },
  },
  contactTitle: {
    marginBottom: theme.spacing(3),
  },
  contactItem: {
    fontSize: '1rem',
    '&:not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      '&:not(:last-child)': {
        marginBottom: theme.spacing(-2),
      },
    },
  },
  contactItemMain: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.24)',
    marginBottom: '32px!important',
    paddingBottom: theme.spacing(4),
  },
  contactItemLeft: {
    marginBottom: theme.spacing(5.5),
  },
  listLeft: {
    flexDirection: 'column',
    padding: theme.spacing(6, 0, 6, 0),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(6, 4),
    },
  },
  twoItemRow: {
    display: 'flex',
    textAlign: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignContent: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  secondRow: {
    margin: theme.spacing(2, 4, 0, 0),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0, 4, 0, 0),
    },
  },
  sendMessageButton: {
    width: 176,
    background: 'black',
    color: 'white',
    fontWeight: 700,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#ef2828',
    },
    [theme.breakpoints.down('sm')]: {
      margin: 'auto',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    marginLeft: '-20px',
  },
  hero: {
    padding: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(8, 0),
    },
  },
}))

type FeatureElement =
  | { type: 'title' | 'subtitle' | 'section' | 'content'; text: string }
  | { type: 'link'; text: string; linkTarget: string }
  | { type: 'separator' }

export const focusKeyPressNext = (e: React.KeyboardEvent, formState?: FormState<any>): void => {
  if (e.key === 'Enter') {
    if (
      formState &&
      (!formState.isValid ||
        !formState.isDirty ||
        (formState.isDirty && !isEmpty(formState.errors)))
    ) {
      e.preventDefault()
    }
    const forNextFocus = document.getElementById('forNextFocus')
    const inputs: HTMLInputElement[] = Array.from(
      forNextFocus?.querySelectorAll('input, select, textarea') ?? [],
    )
    const newArrayInputs = inputs.filter((item) => item.id !== 'prefixCode')
    const selectedInput = document.activeElement
    const indexOf = newArrayInputs.findIndex((item) => item.id === selectedInput?.id)
    if (indexOf + 1 < newArrayInputs.length) newArrayInputs[indexOf + 1].focus()
  }
}

const capabilityElementsA: FeatureElement[] = [
  {
    type: 'separator',
  },
  {
    type: 'title',
    text: 'TalkToUs-H2',
  },
  {
    type: 'content',
    text: 'TalkToUs-P01',
  },
  {
    type: 'subtitle',
    text: 'TalkToUs-P1',
  },
  {
    type: 'content',
    text: 'TalkToUs-P11',
  },
  {
    type: 'subtitle',
    text: 'TalkToUs-P2',
  },
  {
    type: 'content',
    text: 'TalkToUs-P21',
  },
  {
    type: 'subtitle',
    text: 'TalkToUs-P3',
  },
  {
    type: 'content',
    text: 'TalkToUs-P31',
  },
  {
    type: 'subtitle',
    text: 'TalkToUs-P5',
  },
  {
    type: 'content',
    text: 'TalkToUs-P51',
  },
  {
    type: 'separator',
  },
]

export const TalkToUs: FC = () => {
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation()

  const methods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(TalkToUsSchema),
  })

  const { reset } = methods
  const phoneNum = ''

  const onSubmit = useCallback(
    async (formData) => {
      try {
        rxios
          .post('inquiry', {
            fullName: formData?.fullName,
            email: formData?.email,
            companyName: formData?.companyName,
            phone: formData?.phone,
            inquiry: emailEnquiryTypes[formData?.inquiry as keyof typeof emailEnquiryTypes]?.value,
            message: formData?.message,
          })
          .subscribe(
            () => {
              toast.configure()
            },
            (error) => {
              toast.error(i18n.t('errorSendingEmail', 'Error sending email'))
              toast.error(error.message)
            },
            () => {
              reset()
              history.push(PATH_MESSAGE_SENT)
            },
          )
      } catch (e) {
        toast.error(i18n.t('errorSendingEmail', 'Error sending email'))
        toast.error(e.message)
      }
    },
    [history],
  )

  tagManagerHandler('PageTalkToUs')

  return (
    <>
      <Box className={classes.paddingBox}>
        <Container>
          <WebsiteTitle title={'TalkToUs-H'} subtitle={'TalkToUs-H1'} />
          <Hidden mdUp>
            <Box mt={-9} />
          </Hidden>
          <Grid container className={classes.featureContainer}>
            <Grid item xs={12} md={8}>
              <FormProvider {...methods}>
                <form
                  onSubmit={methods.handleSubmit(onSubmit)}
                  id="forNextFocus"
                  onKeyDown={focusKeyPressNext}
                >
                  <Grid container spacing={8}>
                    <Grid item xs={12} md={8} className={classes.block}>
                      <Grid container className={classes.listLeft}>
                        <Grid item xs={12} className={clsx(classes.contactItem)}>
                          <Typography
                            variant={'h3'}
                            className={(classes.contactTitle, classes.contactItemLeft)}
                          >
                            {t('TalkToUs-H3')}
                          </Typography>
                        </Grid>

                        <Box className={classes.twoItemRow}>
                          <FormControl className={clsx(classes.formControl, classes.shortField)}>
                            <FormControlledTextField
                              label={t('TalkToUs-H4')}
                              name="fullName"
                              type="text"
                              fullWidth
                              required={false}
                            />
                          </FormControl>

                          <FormControl className={clsx(classes.formControl, classes.shortField)}>
                            <FormControlledTextField
                              label={t('TalkToUs-H5')}
                              name="email"
                              type="text"
                              fullWidth
                              required={false}
                            />
                          </FormControl>
                        </Box>

                        <Box className={classes.twoItemRow}>
                          <FormControl
                            className={clsx(
                              classes.formControl,
                              classes.secondRow,
                              classes.shortField,
                            )}
                          >
                            <FormControlledTextField
                              label={t('TalkToUs-H6')}
                              name="companyName"
                              type="text"
                              fullWidth
                              required={false}
                            />
                          </FormControl>
                          <Hidden mdUp>
                            <Box mt={-2} />
                          </Hidden>
                          <FormControl className={clsx(classes.formControl, classes.shortField)}>
                            <CountryPhoneSelect
                              key={phoneNum}
                              name="phone"
                              label={t('TalkToUs-H7')}
                              defaultValue={phoneNum}
                            />
                          </FormControl>
                        </Box>

                        <Grid item className={clsx(classes.formControl, classes.longField)}>
                          <FormControl className={classes.formControl}>
                            <FormAutocompleteSelect
                              label={t('TalkToUs-H8')}
                              name="inquiry"
                              data={emailEnquiryArray}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item className={clsx(classes.formControl, classes.longField)}>
                          <FormControlledTextField
                            label={t('TalkToUs-H9')}
                            name="message"
                            type="text"
                            fullWidth
                            required={false}
                            multiline
                            maxRows={3}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Button
                    type="submit"
                    className={classes.sendMessageButton}
                    size="medium"
                    variant="contained"
                    endIcon={<IconArrow />}
                  >
                    {t('TalkToUs-P4')}
                  </Button>
                  <ToastContainer />
                </form>
              </FormProvider>
            </Grid>
            <Grid item xs={12} md={4}>
              <FeatureBox isBlackBg={true} elements={capabilityElementsA} />
            </Grid>
          </Grid>
        </Container>
      </Box>
      <CardMedia component="img" alt="" width="100%" image={imgHero2x} className={classes.hero} />
      <SubSubFooter />
    </>
  )
}
