const incomingMerchantPaymentsApms = {
  type: 'incoming_merchant_payments_apms',
  sticky_note: 'Additional Methods available upon request.<br/>*Restrictions may apply.',
  visible_columns: ['method', 'currency', 'region', 'operation'],
  hidden_columns: [],
  column_names: {
    method: '06',
    currency: '16',
    region: '04',
    operation: '05',
  },
  data: [
    {
      method: 'SEPA Direct Debit',
      currency: 'EUR',
      region: 'Europe',
      operation: 'Pay In, Refund, Subscription',
    },
    {
      method: 'Alipay',
      currency: 'AUD CAD CHF CNY EUR GBP HKD NZD SGD USD',
      region: 'China',
      operation: 'Pay In, Refund',
    },
    {
      method: 'Bancontact',
      currency: 'EUR',
      region: 'Belgium',
      operation: 'Pay In, Refund, Subscription',
    },
    {
      method: 'Blik',
      currency: 'PLN',
      region: 'Poland',
      operation: 'Pay In, Refund',
    },
    {
      method: 'Giropay',
      currency: 'EUR',
      region: 'Germany',
      operation: 'Pay In',
    },
    {
      method: 'EPS',
      currency: 'EUR',
      region: 'Austria',
      operation: 'Pay In',
    },
    {
      method: 'iDeal',
      currency: 'EUR',
      region: 'Netherlands',
      operation: 'Pay In, Refund',
    },
    {
      method: 'MyBank',
      currency: 'EUR',
      region: 'Italy',
      operation: 'Pay In, Refund',
    },
    {
      method: 'PayPal',
      currency: 'ALL CURRENCIES',
      region: 'Worldwide',
      operation: 'Pay In, Refund',
    },
    {
      region:
        'Austria, Australia, Belgium, Bulgaria, Canada, Switzerland, Croatia, Cyprus, Czechia, Denmark, Finland, France, Georgia, Germany, Gibraltar, Hungary, Ireland,  Italy, Liechtenstein,  Lithuania, Luxembourg, Malta, Mexico, Netherlands, Norway, New Zeland,  Peru, Poland, Portugal, Romania, Slovakia, Slovenia, Spain, Sweden, Switzerland, United Kingdom, Uruguay',
      method: 'Paysafecard',
      currency: 'AUD CAD CHF EUR GBP USD PLN NOK RON SEK',
      operation: 'Pay In',
    },
    {
      method: 'Poli',
      currency: 'AUD',
      region: 'Australia,New Zeland',
      operation: 'Pay In',
    },
    {
      method: 'Przelewy24',
      currency: 'PLN',
      region: 'Poland',
      operation: 'Pay In, Refund',
    },
    {
      method: 'Safetypay',
      currency: 'USD',
      region: 'Brazil, Chile, Ecuador, Mexico, Peru',
      operation: 'Pay In',
    },
    {
      region:
        'Austria, Belgium, France, Germany, Italy, Netherlands, Poland, Spain, Sweden, Switzerland',
      method: 'Sofort',
      currency: 'EUR',
      operation: 'Pay In',
    },
    {
      region:
        'Sweden, Finland, Germany, UK, Estonia, Poland, Lithuania, Norway, Latvia, Denmark, Austria, Spain, Czech Republic, Belgium, Netherlands, Slovakia',
      method: 'Trustly',
      currency: 'GBP PLN NOK RON BGN CZK DKK HRK HUF',
      operation: 'Pay In, Payout, Refund',
    },
    {
      method: 'WeChat Pay',
      currency: 'EUR GBP USD',
      region: 'China',
      operation: 'Pay In, Refund',
    },
    {
      method: 'PIX',
      currency: 'BRL',
      region: 'Brazil',
      operation: 'Pay In, Payout',
    },
    {
      method: 'Boleto',
      currency: 'BRL',
      region: 'Brazil',
      operation: 'Pay In, Payout',
    },
    {
      method: 'OXXO',
      currency: 'MXN',
      region: 'Mexico',
      operation: 'Pay In, Payout',
    },
    {
      method: 'SPEI',
      currency: 'MXN',
      region: 'Mexico',
      operation: 'Pay In, Payout',
    },
    {
      method: 'Zimpler',
      currency: 'EUR SEK DKK',
      region: 'Sweden, Denmark, Finland, Estonia',
      operation: 'Pay In, Payout, Refund',
    },
    {
      method: 'PSE -Colombia',
      currency: 'COP',
      region: 'Colombia',
      operation: 'Pay In, Payout',
    },
    {
      method: 'Efecty -Colombia',
      currency: 'COP',
      region: 'Colombia',
      operation: 'Pay In, Payout',
    },
    {
      method: 'ApplePay',
      currency: 'ALL CURRENCIES',
      region: 'Worldwide',
      operation: 'Pay In, Payout, Refund, Subscription',
    },
    {
      method: 'GooglePay',
      currency: 'ALL CURRENCIES',
      region: 'Worldwide',
      operation: 'Pay In, Payout, Refund, Subscription',
    },
  ],
}

const exportedObject = incomingMerchantPaymentsApms

export default exportedObject
