import ReactGA from 'react-ga'
import TagManager from 'react-gtm-module'

export const tagManagerHandler = (dataLayerName: string) => {
  const isGAEnabled = import.meta.env.MODE === 'production'

  const tagManagerArgs = {
    gtmId: isGAEnabled ? /*'G-JV9EF9HJCC'*/ 'GTM-NN3RP9B' : 'GTM-00001',
    dataLayer: {
      userId: '001',
      userProject: 'project',
      page: dataLayerName,
    },
    dataLayerName: dataLayerName,
  }

  ReactGA.initialize(isGAEnabled ? 'UA-195150254' : 'GTM-00001', {
    debug: !isGAEnabled,
    titleCase: false,
    gaOptions: {
      siteSpeedSampleRate: 100,
    },
  })

  ReactGA.pageview(window.location.pathname + window.location.search)

  return TagManager.initialize(tagManagerArgs)
}
