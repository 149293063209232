const outboundWalletsInstant = {
  type: 'outbound_wallets_instant',
  sticky_note: '*Restrictions may apply.',
  visible_columns: ['method', 'currency', 'region', 'speed'],
  hidden_columns: [],
  column_names: {
    method: '06',
    currency: '16',
    region: '04',
    speed: '13',
  },
  data: [
    {
      method: 'bKash',
      currency: 'BDT',
      region: 'Bangladesh',
      speed: 'Instant',
    },
    {
      method: 'MTN',
      currency: 'XOF',
      region: 'Benin',
      speed: 'Instant',
    },
    {
      method: 'Moov',
      currency: 'XOF',
      region: 'Benin',
      speed: 'Instant',
    },
    {
      method: 'BMO',
      currency: 'XOF',
      region: 'Benin',
      speed: 'Instant',
    },
    {
      method: 'BTC Wallets ',
      currency: 'BWP',
      region: 'Botswana',
      speed: 'Instant',
    },
    {
      method: 'Moov',
      currency: 'XOF',
      region: 'Burkina Faso',
      speed: 'Instant',
    },
    {
      method: 'Orange',
      currency: 'XOF',
      region: 'Burkina Faso',
      speed: 'Instant',
    },
    {
      method: 'EcoCash',
      currency: 'BIF',
      region: 'Burundi',
      speed: 'Instant',
    },
    {
      method: 'UBA Bank Accounts ',
      currency: 'XAF',
      region: 'Cameroon',
      speed: 'Instant',
    },
    {
      method: 'MTN',
      currency: 'XAF',
      region: 'Cameroon',
      speed: 'Instant',
    },
    {
      method: 'Orange',
      currency: 'XAF',
      region: 'Cameroon',
      speed: 'Instant',
    },
    {
      method: 'Orange',
      currency: 'XAF',
      region: 'Central African Republic',
      speed: 'Instant',
    },
    {
      method: 'Airtel',
      currency: 'XAF',
      region: 'Chad',
      speed: 'Instant',
    },
    {
      method: 'Moov',
      currency: 'XAF',
      region: 'Chad',
      speed: 'Instant',
    },
    {
      method: 'Movii',
      currency: 'COP',
      region: 'Colombia',
      speed: 'Instant (One time registration required)',
    },
    {
      method: 'MVola',
      currency: 'KMF',
      region: 'Comoros',
      speed: 'Instant',
    },
    {
      method: 'Airtel',
      currency: 'XAF',
      region: 'Congo B',
      speed: 'Instant',
    },
    {
      method: 'MTN',
      currency: 'XAF',
      region: 'Congo B',
      speed: 'Instant',
    },
    {
      method: 'Airtel',
      currency: 'USD',
      region: 'DRC',
      speed: 'Instant',
    },
    {
      method: 'Airtel',
      currency: 'XAF',
      region: 'Gabon',
      speed: 'Instant',
    },
    {
      method: 'Moov',
      currency: 'XAF',
      region: 'Gabon',
      speed: 'Instant',
    },
    {
      method: 'AfriMoney',
      currency: 'GMD',
      region: 'Gambia',
      speed: 'Instant',
    },
    {
      method: 'QMoney',
      currency: 'GMD',
      region: 'Gambia',
      speed: 'Instant',
    },
    {
      method: 'Wave',
      currency: 'GMD',
      region: 'Gambia',
      speed: 'Instant',
    },
    {
      method: 'MTN',
      currency: 'GHS',
      region: 'Ghana',
      speed: 'Instant',
    },
    {
      method: 'Airtel-Tigo ',
      currency: 'GHS',
      region: 'Ghana',
      speed: 'Instant',
    },
    {
      method: 'Vodafone',
      currency: 'GHS',
      region: 'Ghana',
      speed: 'Instant',
    },
    {
      method: 'MTN',
      currency: 'XOF',
      region: 'Guinea Bissau',
      speed: 'Instant',
    },
    {
      method: 'MTN',
      currency: 'GNF',
      region: 'Guinea Conakry',
      speed: 'Instant',
    },
    {
      method: 'MonCash',
      currency: 'HTG',
      region: 'Haiti',
      speed: 'Instant',
    },
    {
      method: 'GoPay',
      currency: 'IDR',
      region: 'Indonesia',
      speed: 'Instant',
    },
    {
      method: 'LinkAja',
      currency: 'IDR',
      region: 'Indonesia',
      speed: 'Instant',
    },
    {
      method: 'OVO',
      currency: 'IDR',
      region: 'Indonesia',
      speed: 'Instant',
    },
    {
      method: 'DANA',
      currency: 'IDR',
      region: 'Indonesia',
      speed: 'Instant',
    },
    {
      method: 'ShopeePay',
      currency: 'IDR',
      region: 'Indonesia',
      speed: 'Instant',
    },
    {
      method: 'Orange',
      currency: 'XOF',
      region: 'Ivory Coast',
      speed: 'Instant',
    },
    {
      method: 'MTN',
      currency: 'XOF',
      region: 'Ivory Coast',
      speed: 'Instant',
    },
    {
      method: 'Moov',
      currency: 'XOF',
      region: 'Ivory Coast',
      speed: 'Instant',
    },
    {
      method: 'Airtel',
      currency: 'KES',
      region: 'Kenya',
      speed: 'Instant',
    },
    {
      method: 'EcoCash',
      currency: 'LSL',
      region: 'Lesotho',
      speed: 'Instant',
    },
    {
      method: 'MTN',
      currency: 'USD',
      region: 'Liberia',
      speed: 'Instant',
    },
    {
      method: 'Airtel',
      currency: 'MGA',
      region: 'Madagascar',
      speed: 'Instant',
    },
    {
      method: 'Airtel',
      currency: 'MWK',
      region: 'Malawi',
      speed: 'Instant',
    },
    {
      method: 'TnG Wallets ',
      currency: 'MYR',
      region: 'Malaysia',
      speed: 'Instant',
    },
    {
      method: 'Orange',
      currency: 'XOF',
      region: 'Mali',
      speed: 'Instant',
    },
    {
      method: 'Malitel',
      currency: 'XOF',
      region: 'Mali',
      speed: 'Instant',
    },
    {
      method: 'Mauripay',
      currency: 'MRU',
      region: 'Mauritania',
      speed: 'Instant',
    },
    {
      method: 'Sedad',
      currency: 'MRU',
      region: 'Mauritania',
      speed: 'Instant',
    },
    {
      method: 'Click',
      currency: 'MRU',
      region: 'Mauritania',
      speed: 'Instant',
    },
    {
      method: 'M-Pesa  ',
      currency: 'MZN',
      region: 'Mozambique',
      speed: 'Instant',
    },
    {
      method: 'GME Wallet',
      currency: 'NPR',
      region: 'Nepal',
      speed: 'Instant',
    },
    {
      method: 'eSewa Wallet',
      currency: 'NPR',
      region: 'Nepal',
      speed: 'Instant',
    },
    {
      method: 'Khalti Wallet',
      currency: 'NPR',
      region: 'Nepal',
      speed: 'Instant',
    },
    {
      method: 'IME Wallet',
      currency: 'NPR',
      region: 'Nepal',
      speed: 'Instant',
    },
    {
      method: 'AirPak',
      currency: 'USD',
      region: 'Nicaragua',
      speed: 'Same Day/T+1 Cutoff: 1600 hours GMT-6',
    },
    {
      method: 'OPay',
      currency: 'NGN',
      region: 'Nigeria',
      speed: 'Instant',
    },
    {
      method: 'Palmpay',
      currency: 'NGN',
      region: 'Nigeria',
      speed: 'Instant',
    },
    {
      method: 'Kuda and Paga ',
      currency: 'NGN',
      region: 'Nigeria',
      speed: 'Instant',
    },
    {
      method: 'JazzCash',
      currency: 'PKR',
      region: 'Pakistan',
      speed: 'Instant',
    },
    {
      method: 'EasyPaisa',
      currency: 'PKR',
      region: 'Pakistan',
      speed: 'Instant',
    },
    {
      method: 'SadaPay',
      currency: 'PKR',
      region: 'Pakistan',
      speed: 'Instant',
    },
    {
      method: 'Gcash',
      currency: 'PHP',
      region: 'Philippines',
      speed: 'Instant',
    },
    {
      method: 'PayMaya',
      currency: 'PHP',
      region: 'Philippines',
      speed: 'Instant',
    },
    {
      method: 'Coins',
      currency: 'PHP',
      region: 'Philippines',
      speed: 'Instant',
    },
    {
      method: 'SPY',
      currency: 'PHP',
      region: 'Philippines',
      speed: 'Instant',
    },
    {
      method: 'DCPay',
      currency: 'PHP',
      region: 'Philippines',
      speed: 'Instant',
    },
    {
      method: 'MTN',
      currency: 'RWF',
      region: 'Rwanda',
      speed: 'Instant',
    },
    {
      method: 'Airtel-Tigo ',
      currency: 'RWF',
      region: 'Rwanda',
      speed: 'Instant',
    },
    {
      method: 'Orange',
      currency: 'XOF',
      region: 'Senegal',
      speed: 'Instant',
    },
    {
      method: 'Tigo',
      currency: 'XOF',
      region: 'Senegal',
      speed: 'Instant',
    },
    {
      method: 'Wave',
      currency: 'XOF',
      region: 'Senegal',
      speed: 'Instant',
    },
    {
      method: 'Wizall',
      currency: 'XOF',
      region: 'Senegal',
      speed: 'Instant',
    },
    {
      method: 'Orange',
      currency: 'SLE',
      region: 'Sierra Leone',
      speed: 'Instant',
    },

    {
      method: 'mGurush Wallets',
      currency: 'SSP/USD',
      region: 'South Sudan',
      speed: 'Instant',
    },
    {
      method: 'MTN',
      currency: 'SSP/USD',
      region: 'South Sudan',
      speed: 'Instant',
    },
    {
      method: 'Airtel',
      currency: 'TZS',
      region: 'Tanzania',
      speed: 'Instant',
    },
    {
      method: 'Vodacom',
      currency: 'TZS',
      region: 'Tanzania',
      speed: 'Instant',
    },
    {
      method: 'Tigo',
      currency: 'TZS',
      region: 'Tanzania',
      speed: 'Instant',
    },
    {
      method: 'Moov',
      currency: 'XOF',
      region: 'Togo',
      speed: 'Instant',
    },
    {
      method: 'UPTion Wallets  ',
      currency: 'TRY/USD/EUR',
      region: 'Turkey',
      speed:
        'Real-time for transactions processed during business hours 9:00 AM to 05:00 PM (GMT+3)',
    },
    {
      method: 'MTN',
      currency: 'UGX',
      region: 'Uganda',
      speed: 'Instant',
    },
    {
      method: 'Airtel',
      currency: 'UGX',
      region: 'Uganda',
      speed: 'Instant',
    },
    {
      method: 'Momo',
      currency: 'VND',
      region: 'Vietnam',
      speed: 'Instant',
    },
    {
      method: 'VNPT Pay',
      currency: 'VND',
      region: 'Vietnam',
      speed: 'Instant',
    },
    {
      method: 'ZaloPay',
      currency: 'VND',
      region: 'Vietnam',
      speed: 'Instant',
    },
    {
      method: 'VNPAY',
      currency: 'VND',
      region: 'Vietnam',
      speed: 'Instant',
    },
    {
      method: 'Airtel',
      currency: 'ZMW',
      region: 'Zambia',
      speed: 'Instant',
    },
    {
      method: 'MTN',
      currency: 'ZMW',
      region: 'Zambia',
      speed: 'Instant',
    },
    {
      method: 'EcoCash',
      currency: 'USD',
      region: 'Zimbabwe',
      speed: 'Instant',
    },
    {
      method: 'AliChat Pay',
      currency: 'CNY',
      region: 'China',
      speed: 'Instant',
    },
    {
      method: 'Wechat Pay',
      currency: 'CNY',
      region: 'China',
      speed: 'Instant',
    },
  ],
}

const exportedObject = outboundWalletsInstant

export default exportedObject
