import React, { FC } from 'react'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core'
import { LinkSection, SectionTitle, SectionContent } from '../Common'
import {
  PATH_NEWS_AND_MEDIA,
  PATH_BOARD_DIRECTORS,
  PATH_EXECUTIVE_MANAGEMENT,
  PATH_FAQ,
} from '../../constants'

const useStyles = makeStyles((theme) => ({
  aboutContainer: {
    marginLeft: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(8),
      marginLeft: theme.spacing(0),
    },
  },
}))

const links = [
  {
    text: 'MainMenu-P21',
    url: PATH_NEWS_AND_MEDIA,
  },
  {
    text: 'MainMenu-P12',
    url: PATH_BOARD_DIRECTORS,
  },
  {
    text: 'MainMenu-P29',
    url: PATH_FAQ,
  },
  {
    text: 'MainMenu-P13',
    url: PATH_EXECUTIVE_MANAGEMENT,
  },
]

export const AboutBivial: FC = () => {
  const classes = useStyles()

  return (
    <>
      <Box className={classes.aboutContainer}>
        <SectionTitle title="Home-H8" />
        <SectionContent text="Home-P81" />
        <LinkSection links={links} />
      </Box>
    </>
  )
}
