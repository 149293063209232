import { FC, default as React, useCallback } from 'react'
import { useHistory } from 'react-router'
import { makeStyles } from '@material-ui/core/styles'
import CardMedia from '@material-ui/core/CardMedia'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import { Button, Hidden } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import {
  WebsiteTitle,
  MediaObject,
  FeatureElements,
  SectionTitle,
  SubSubFooter,
  SectionContent,
} from '../Common'
import { CardsTeaser } from './CardsTeaser'
import { tagManagerHandler } from '../../utils'
import card from '../../assets/images/img-cards-card@2x.png'
import icon1 from '../../assets/images/img-cards-apple-pay@2x.png'
import icon2 from '../../assets/images/img-cards-google-pay@2x.png'
import applePay from '../../assets/images/img-cards-apple-phone@2x.png'
import googlePay from '../../assets/images/img-cards-google-phone@2x.png'
import { PATH_APPLE_PAY, PATH_GOOGLE_PAY } from '../../constants'

const useStyles = makeStyles((theme) => ({
  paddingBox: {
    padding: theme.spacing(2.5, 5),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1.5, 2),
    },
  },
  featureContainer: {
    maxWidth: '1100px',
    margin: '0 auto',
  },
  mediaObjectContainer: {
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(6),
    },
  },
  centerSection: {
    maxWidth: '600px',
    margin: '-30px auto 30px',
    '& > p': {
      textAlign: 'center',
    },
    '& > div > h2': {
      textAlign: 'center',
    },
  },
  icon: {
    height: '24px',
    width: 'auto',
  },
  iconLarge: {
    height: '36px',
    width: 'auto',
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      margin: 'auto',
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },
  },
  simpleFeatureContainer: {
    maxWidth: '1050px',
    margin: '0 auto',
    marginBottom: theme.spacing(6),
  },
  buttonContainer: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      width: '100%',
    },
  },
  button: {
    fontWeight: 700,
    minWidth: 200,
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: 350,
      margin: 'auto',
    },
  },
  paddingLeft3: {
    paddingLeft: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      textAlign: 'center',
      margin: 'auto',
    },
  },
  maxWidth350: {
    maxWidth: 350,
    margin: 'auto',
  },
}))

type SimpleFeatureElement = { title: string; content: string }

const features: SimpleFeatureElement[] = [
  {
    title: 'Cards-H4',
    content: 'Cards-P4',
  },
  {
    title: 'Cards-H5',
    content: 'Cards-P5',
  },
  {
    title: 'Cards-H8',
    content: 'Cards-P8',
  },
  {
    title: 'Cards-H9',
    content: 'Cards-P9',
  },
]

export const CardsPage: FC = () => {
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation()

  tagManagerHandler('PageCard')

  const clickHandlerApple = useCallback(() => {
    history.push(PATH_APPLE_PAY)
  }, [history])

  const clickHandlerGoogle = useCallback(() => {
    history.push(PATH_GOOGLE_PAY)
  }, [history])

  return (
    <>
      <Box className={classes.paddingBox}>
        <Container>
          <WebsiteTitle title={'Cards-H1'} />
          <Grid container className={classes.featureContainer}>
            <Grid container>
              <MediaObject
                imagePlacement={'right'}
                image={card}
                imageSize={'large'}
                fullHeight={false}
              >
                <CardsTeaser />
              </MediaObject>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Box className={classes.centerSection}>
                <SectionTitle title="Cards-H3" danger />
              </Box>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} className={classes.simpleFeatureContainer}>
              <FeatureElements elements={features} fourElements />
            </Grid>
          </Grid>
          <Grid container className={classes.featureContainer}>
            <Grid container>
              <MediaObject imagePlacement={'right'} image={applePay} fullHeight={false}>
                <Box className={classes.paddingLeft3}>
                  <CardMedia component="img" alt="" image={icon1} className={classes.iconLarge} />
                  <Hidden smDown>
                    <SectionContent text={'Cards-P10'} danger />
                  </Hidden>
                  <Hidden mdUp>
                    <Box className={classes.maxWidth350}>
                      <SectionContent text={'Cards-P10'} danger />
                    </Box>
                  </Hidden>
                  <Box mt={4} mb={6} className={classes.buttonContainer}>
                    <Button
                      size="medium"
                      variant="contained"
                      color="primary"
                      onClick={clickHandlerApple}
                      className={classes.button}
                    >
                      {t('Cards-CTA-Learn-More')}
                    </Button>
                  </Box>
                </Box>
              </MediaObject>
            </Grid>
            <Grid container>
              <MediaObject imagePlacement={'left'} image={googlePay} fullHeight={false}>
                <Grid container>
                  <Hidden smDown>
                    <Grid item xs={12} md={4} />
                  </Hidden>
                  <Grid item xs={12} md={8}>
                    <CardMedia component="img" alt="" image={icon2} className={classes.iconLarge} />
                    <Hidden smDown>
                      <SectionContent text={'Cards-P11'} danger />
                    </Hidden>
                    <Hidden mdUp>
                      <Box className={classes.maxWidth350}>
                        <SectionContent text={'Cards-P11'} danger />
                      </Box>
                    </Hidden>
                    <Box mt={4} mb={6} className={classes.buttonContainer}>
                      <Button
                        size="medium"
                        variant="contained"
                        color="primary"
                        onClick={clickHandlerGoogle}
                        className={classes.button}
                      >
                        {t('Cards-CTA-Learn-More')}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </MediaObject>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <SubSubFooter />
    </>
  )
}
