import React, { FC } from 'react'
import { Container, makeStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import logo from '../../assets/images/logo.svg'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItemText from '@material-ui/core/ListItemText'
import clsx from 'clsx'
import LinkedIn from '../../assets/icons/linkedin.svg?react'
import Twitter from '../../assets/icons/twitter.svg?react'
import Youtube from '../../assets/icons/icon-youtube.svg?react'
import Medium from '../../assets/icons/icon-medium.svg?react'
import {
  PATH_ACCEPTED_COUNTRIES,
  PATH_ROOT,
  PATH_NEWS_AND_MEDIA,
  PATH_USE_CASES,
  PATH_CARD,
  PATH_BUSINESS_ACCOUNT,
  PATH_PARTNERSHIPS,
  PATH_FAQ,
  PATH_TALKTOUS,
  PATH_ENTREPRENEUR_ACCOUNTS,
  PATH_PAYMENT_CAPABILITIES,
} from '../../constants'
import { Theme } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import IconButton from '@material-ui/core/IconButton'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: 'white',
    [theme.breakpoints.up('md')]: {
      '& .MuiListItem-gutters': {
        marginLeft: '-16px',
      },
    },
    '& .MuiContainer-root': {
      // maxWidth: 1072,
    },
  },
  subFooterGrid: {
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      // alignItems: 'center',
      // whiteSpace: 'nowrap',
      // textAlign: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      alignItems: 'center',
      textAlign: 'center',
    },
  },
  footerNavColumnList: {
    display: 'block',
    marginTop: '22px',
    [theme.breakpoints.down('sm')]: {
      '& .MuiListItem-root': {
        textAlign: 'center',
      },
    },
  },
  body: {
    fontFamily: '"Helvetica Neue", "Arial", sans-serif',
    fontSize: '1rem',
    textDecoration: 'unset',
    color: 'black',
  },
  body2: {
    display: 'block',
    marginBottom: '22px',
    fontFamily: '"Helvetica Neue", "Arial", sans-serif',
    fontSize: '0.875rem',
    opacity: '64%',
    textDecoration: 'none',
    color: 'black',
    whiteSpace: 'normal',
    '&:hover': {
      opacity: 1,
      textDecoration: 'underline',
    },
  },
  mrgTop: {
    marginTop: theme.spacing(5),
  },
  subFooterNav: {
    justifyContent: 'space-around',
    '& .MuiListItemText-primary': {
      fontSize: 'inherit',
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  subFooterNavItem: {
    padding: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      width: '20%',
      maxWidth: 200,
      minWidth: 100,
    },
    '& .MuiTypography-subtitle2': {
      fontSize: 'inherit',
    },
  },
  socials: {
    minWidth: '300px',
    color: '#555',
    '& .MuiIconButton-root': {
      marginTop: theme.spacing(-1.5),
    },
    [theme.breakpoints.only('sm')]: {
      marginLeft: theme.spacing(-1),
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
    marginBottom: theme.spacing(5),
  },
  logoImg: {
    display: 'inline-block',
  },
  phrase: {
    fontSize: '14px',
  },
  leftMargin: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(-2),
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
}))

export const SubFooter: FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const loginLink = `https://dashboard.bivial.com/login`
  const signupLink = 'https://dashboard.bivial.com/signup'

  return (
    <Box pt={6} mt={6} className={classes.root}>
      <Container>
        <Grid container spacing={3} className={classes.subFooterGrid}>
          <Grid item>
            <Grid container direction={'column'}>
              <Grid item>
                <Box className={classes.socials}>
                  <Link className={classes.logoImg} to={PATH_ROOT}>
                    <img src={logo} alt={'Klarpay AG'} />
                  </Link>
                </Box>
              </Grid>
              <Grid item>
                <Grid item>
                  <Grid container spacing={1} className={clsx(classes.socials, classes.leftMargin)}>
                    <IconButton
                      aria-label="LinkedIn"
                      target="_blank"
                      href="https://www.linkedin.com/company/bivial/"
                    >
                      <LinkedIn />
                    </IconButton>
                    <IconButton aria-label="X" target="_blank" href="https://x.com/Bivial_AG">
                      <Twitter />
                    </IconButton>
                    <IconButton
                      aria-label="Youtube"
                      target="_blank"
                      href=" https://www.youtube.com/@bivialag"
                    >
                      <Youtube />
                    </IconButton>
                    <IconButton
                      aria-label="Medium"
                      target="_blank"
                      href="https://medium.com/@bivial"
                    >
                      <Medium />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item container className={classes.subFooterNav}>
            <Grid item className={classes.subFooterNavItem}>
              <Grid container direction={'column'}>
                <Link className={classes.body} to={PATH_CARD}>
                  <Typography variant={'subtitle2'}>{t('MainMenu-P20')}</Typography>
                </Link>
                <List
                  component="nav"
                  className={classes.footerNavColumnList}
                  disablePadding={false}
                >
                  <Link className={classes.body2} to={PATH_BUSINESS_ACCOUNT}>
                    <ListItemText primary={t('MainMenu-P32')} />
                  </Link>
                  <Link className={classes.body2} to={PATH_ENTREPRENEUR_ACCOUNTS}>
                    <ListItemText primary={t('MainMenu-P30')} />
                  </Link>
                  <Link className={classes.body2} to={PATH_PAYMENT_CAPABILITIES}>
                    <ListItemText primary={t('MainMenu-P72')} />
                  </Link>
                </List>
              </Grid>
            </Grid>

            <Grid item className={classes.subFooterNavItem}>
              <Link className={classes.body} to={PATH_FAQ}>
                <Typography variant={'subtitle2'}>{t('Footer-Helpful-Links')}</Typography>
              </Link>
              <List component="nav" className={classes.footerNavColumnList} disablePadding={false}>
                <Link className={classes.body2} to={PATH_FAQ}>
                  <ListItemText primary={t('MainMenu-P29')} />
                </Link>
                <Link className={classes.body2} to={PATH_USE_CASES}>
                  <ListItemText primary={t('MainMenu-P15')} />
                </Link>
                <Link className={classes.body2} to={PATH_ACCEPTED_COUNTRIES}>
                  <ListItemText primary={t('Footer-Accepted-Countries')} />
                </Link>

                <Link className={classes.body2} to={PATH_PARTNERSHIPS}>
                  <ListItemText primary={t('MainMenu-P28')} />
                </Link>

                <Link className={classes.body2} to={PATH_NEWS_AND_MEDIA}>
                  <ListItemText primary={t('MainMenu-P21')} />
                </Link>
              </List>
            </Grid>

            <Grid item className={classes.subFooterNavItem}>
              <Link className={classes.body} to={PATH_TALKTOUS}>
                <Typography variant={'subtitle2'}>{t('TalkToUs')}</Typography>
              </Link>
              <List component="nav" className={classes.footerNavColumnList} disablePadding={false}>
                <a className={classes.body2} href={signupLink}>
                  <ListItemText primary={t('COMMON-CTA-SignUp')} />
                </a>
                <a className={classes.body2} href={loginLink}>
                  <ListItemText primary={t('Login')} />
                </a>
                <Link className={classes.body2} to={PATH_TALKTOUS}>
                  <ListItemText primary={t('TalkToUs')} />
                </Link>
              </List>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}
