import React, { FC, useCallback, useEffect, useState } from 'react'
import clsx from 'clsx'
import { countries } from '@klarpay/enums'
import { Country } from '@klarpay/enums'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core'
import Search from '../../../assets/icons/img-search.svg?react'

import {
  PATH_BUSINESS_ACCOUNT,
  PATH_PAYMENT_CAPABILITIES_CATEGORY,
  PATH_PAYMENT_CAPABILITIES_CATEGORY_SUBCATEGORY,
} from '../../../constants'
import capabilities from './capabilitiesData'

import { FlagIcon } from '../'

const useStyles = makeStyles((theme) => ({
  categoryMenu: {
    float: 'left',
    marginRight: theme.spacing(5),
    marginTop: theme.spacing(1),
    padding: '4px 8px',
    fontSize: '1.1rem',
    fontWeight: 700,
    cursor: 'pointer',
    '&:last-child': {
      marginRight: theme.spacing(0),
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(2),
      '&:last-child': {
        marginRight: theme.spacing(0),
      },
    },
  },
  categoryselected: {
    backgroundColor: '#ef2828',
    color: 'white',
    padding: '4px 8px',
  },
  subcategoryMenu: {
    float: 'left',
    marginRight: theme.spacing(4),
    marginTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    fontSize: '1rem',
    fontWeight: 400,
    cursor: 'pointer',
    '&:last-child': {
      marginRight: theme.spacing(0),
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(2),
      '&:last-child': {
        marginRight: theme.spacing(0),
      },
    },
  },
  subcategoryselected: {
    borderBottom: '4px solid #ef2828',
  },
  table: {
    width: '900px',
    maxWidth: '900px',
    margin: 'auto',
    marginBottom: '40px',
    tableLayout: 'fixed',
    textWrap: 'wrap',
  },
  tableMobileWidth: {
    width: '100%',
  },
  textCenter: {
    textAlign: 'center',
    // marginBottom: theme.spacing(4),
  },
  textWidth: {
    margin: '4px 24px 10px 10px',
    textAlign: 'right',
    [theme.breakpoints.down('md')]: {
      margin: 'auto',
      textAlign: 'center',
    },
  },
  searchWrapper: {
    marginTop: '-4px',
    marginBottom: '50px',
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      justifyContent: 'center',
      marginTop: '24px',
    },
  },
  horizontalScrollerWrapper: {
    [theme.breakpoints.down('sm')]: {
      overflowX: 'auto',
      msOverflowStyle: 'none',
      scrollbarWidth: 'none',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
  },
  horizontalScrollerWrapper1: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '100px',
    },
  },
  horizontalScrollerWrapper2: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0px',
    },
  },
  horizontalScrollerWrapper3: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0px',
    },
  },
  horizontalScrollerWrapper4: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '300px',
    },
  },
  horizontalScroller: {
    position: 'relative',
  },
  horizontalScroller1: {
    minWidth: '444px',
  },
  horizontalScroller2: {
    minWidth: '230px',
  },
  horizontalScroller3: {
    minWidth: '170px',
  },
  horizontalScroller4: {
    minWidth: '630px',
  },
}))

enum CapabilityCategoryType {
  AcceptPayments = 'AcceptPayments',
  IncomingTransactional = 'IncomingTransactional',
  OutgoingPayments = 'OutgoingPayments',
}

enum CapabilityAcceptPaymentsSubcategoryType {
  AcceptPaymentsBankTransfers = 'AcceptPaymentsBankTransfers',
  AcceptPaymentsApms = 'AcceptPaymentsApms',
  AcceptPaymentsCards = 'AcceptPaymentsCards',
}

enum CapabilityIncomingTransactionalSubcategoryType {
  AccountCurrencies = 'AccountCurrencies',
}

enum CapabilityOutgoingPaymentsSubcategoryType {
  InstantBankTransfers = 'InstantBankTransfers',
  InternationalWires = 'InternationalWires',
  LocalAch = 'LocalAch',
  WalletsInstant = 'WalletsInstant',
  PushToCard = 'PushToCard',
}

type CapabilitySubcategories = {
  [CapabilityCategoryType.AcceptPayments]: CapabilityAcceptPaymentsSubcategoryType
  [CapabilityCategoryType.IncomingTransactional]: CapabilityIncomingTransactionalSubcategoryType
  [CapabilityCategoryType.OutgoingPayments]: CapabilityOutgoingPaymentsSubcategoryType
}

// Union type for all subcategory enums
type CapabilitySubcategory =
  | CapabilityAcceptPaymentsSubcategoryType
  | CapabilityIncomingTransactionalSubcategoryType
  | CapabilityOutgoingPaymentsSubcategoryType

// Mapping functions for categories (from previous code)

const categoryToStringMap: { [K in CapabilityCategoryType]: string } = {
  [CapabilityCategoryType.AcceptPayments]: 'accept-payments',
  [CapabilityCategoryType.IncomingTransactional]: 'treasury',
  [CapabilityCategoryType.OutgoingPayments]: 'send-payments',
}

const stringToCategoryMap: { [key: string]: CapabilityCategoryType } = {
  'accept-payments': CapabilityCategoryType.AcceptPayments,
  treasury: CapabilityCategoryType.IncomingTransactional,
  'send-payments': CapabilityCategoryType.OutgoingPayments,
}

const categoryString = (category: CapabilityCategoryType): string => {
  return categoryToStringMap[category]
}

const categoryEnum = (category: string): CapabilityCategoryType => {
  return stringToCategoryMap[category] //|| CapabilityCategoryType.OutgoingPayments
}

const subcategoryToStringMap = {
  [CapabilityCategoryType.AcceptPayments]: {
    [CapabilityAcceptPaymentsSubcategoryType.AcceptPaymentsBankTransfers]: 'bank-transfers',
    [CapabilityAcceptPaymentsSubcategoryType.AcceptPaymentsApms]: 'apms',
    [CapabilityAcceptPaymentsSubcategoryType.AcceptPaymentsCards]: 'cards',
  },
  [CapabilityCategoryType.IncomingTransactional]: {
    [CapabilityIncomingTransactionalSubcategoryType.AccountCurrencies]: 'account-currencies',
  },
  [CapabilityCategoryType.OutgoingPayments]: {
    [CapabilityOutgoingPaymentsSubcategoryType.InternationalWires]: 'international-payouts',
    [CapabilityOutgoingPaymentsSubcategoryType.LocalAch]: 'local-payouts',
    [CapabilityOutgoingPaymentsSubcategoryType.WalletsInstant]: 'local-alternative-payouts',
  },
} as const

const stringToSubcategoryMap: {
  [C in CapabilityCategoryType]: {
    [key: string]: CapabilitySubcategories[C][keyof CapabilitySubcategories[C]]
  }
} = {
  [CapabilityCategoryType.AcceptPayments]: {
    apms: CapabilityAcceptPaymentsSubcategoryType.AcceptPaymentsApms,
    cards: CapabilityAcceptPaymentsSubcategoryType.AcceptPaymentsCards,
    'bank-transfers': CapabilityAcceptPaymentsSubcategoryType.AcceptPaymentsBankTransfers,
  },
  [CapabilityCategoryType.IncomingTransactional]: {
    'account-currencies': CapabilityIncomingTransactionalSubcategoryType.AccountCurrencies,
  },
  [CapabilityCategoryType.OutgoingPayments]: {
    //'instant-bank-transfers': CapabilityOutgoingPaymentsSubcategoryType.InstantBankTransfers,
    'international-payouts': CapabilityOutgoingPaymentsSubcategoryType.InternationalWires,
    'local-payouts': CapabilityOutgoingPaymentsSubcategoryType.LocalAch,
    'local-alternative-payouts': CapabilityOutgoingPaymentsSubcategoryType.WalletsInstant,
    //'push-to-card': CapabilityOutgoingPaymentsSubcategoryType.PushToCard,
  },
}

function subcategoryString<C extends CapabilityCategoryType>(
  category: C,
  subcategory: keyof typeof subcategoryToStringMap[C],
): string {
  return subcategoryToStringMap[category][subcategory] as string
}

function subcategoryEnum<C extends CapabilityCategoryType>(
  category: C,
  subcategoryStr: string,
): CapabilitySubcategories[C] | undefined {
  const subcategoryMap = stringToSubcategoryMap[category]
  if (subcategoryStr in subcategoryMap) {
    return subcategoryMap[
      subcategoryStr as keyof typeof subcategoryMap
    ] as CapabilitySubcategories[C]
  } else {
    return undefined
  }
}

type Capability = {
  type: unknown
  sticky_note: string
  visible_columns: string[]
  hidden_columns: string[]
  column_names: { [key: string]: string }
  data: unknown[]
}

type CategoryMenuItemProps = {
  selectedCategory: string
  elementCategory: CapabilityCategoryType
  stringElement: string
  search: boolean
  setSelectedCategory: (category: CapabilityCategoryType) => void
  clickHandler: (route: string) => void
}

const CategoryMenuItem: FC<CategoryMenuItemProps> = ({
  selectedCategory,
  elementCategory,
  stringElement,
  setSelectedCategory,
  clickHandler,
  search,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Typography
      className={clsx(
        classes.categoryMenu,
        selectedCategory === elementCategory && classes.categoryselected,
      )}
      onClick={() => {
        setSelectedCategory(elementCategory)
        clickHandler(
          !search
            ? PATH_BUSINESS_ACCOUNT
            : PATH_PAYMENT_CAPABILITIES_CATEGORY.replace(
                ':category',
                categoryString(elementCategory),
              ).replace(':subcategory', ''),
        )
      }}
    >
      {t(stringElement)}
    </Typography>
  )
}

type SubcategoryMenuItemProps = {
  selectedSubcategory: string
  elementCategory: CapabilityCategoryType
  elementSubcategory: CapabilitySubcategory
  stringElement: string
  search: boolean
  setSelectedSubcategory: (subcategory: CapabilitySubcategory) => void
  clickHandler: (route: string) => void
}

const SubcategoryMenuItem: FC<SubcategoryMenuItemProps> = ({
  selectedSubcategory,
  elementCategory,
  elementSubcategory,
  stringElement,
  setSelectedSubcategory,
  clickHandler,
  search,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Typography
      className={clsx(
        classes.subcategoryMenu,
        selectedSubcategory === elementSubcategory && classes.subcategoryselected,
      )}
      onClick={() => {
        setSelectedSubcategory(
          elementSubcategory as CapabilitySubcategories[CapabilityCategoryType],
        )
        clickHandler(
          !search
            ? PATH_BUSINESS_ACCOUNT
            : PATH_PAYMENT_CAPABILITIES_CATEGORY_SUBCATEGORY.replace(
                ':category',
                categoryString(elementCategory),
              ).replace(
                ':subcategory',
                subcategoryString(
                  elementCategory,
                  elementSubcategory as keyof typeof subcategoryToStringMap[CapabilityCategoryType],
                ),
              ),
        )
      }}
    >
      {t(stringElement)}
    </Typography>
  )
}

type CapabilitiesDataTableProps = {
  capability: Capability
  highlight: string
}

const CapabilitiesDataTable: FC<CapabilitiesDataTableProps> = ({ capability, highlight }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <>
      <Grid item xs={12}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {capability.visible_columns.map((column, index) => (
                <TableCell key={index}>
                  <b>{t(`Capabilities-Column-Names-${capability.column_names[column]}`)}</b>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {capability.data.map((row: any, i: number) => (
              <TableRow
                key={`row-${i}`}
                style={{
                  backgroundColor: i % 2 === 0 ? '#eee' : '',
                  border:
                    capability.visible_columns
                      .map((s: string) => row[s] === highlight)
                      .filter((s: boolean) => s).length === 1
                      ? '2px solid #000'
                      : '',
                }}
              >
                {capability.visible_columns.map((column, index) => (
                  <TableCell key={index}>{row[column]}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
      {!!capability.sticky_note && (
        <Grid item xs={12}>
          <div dangerouslySetInnerHTML={{ __html: t(capability.sticky_note) }} />
        </Grid>
      )}
    </>
  )
}

type CountrySelectorProps = {
  onSelect: (country: Country | undefined) => void
  searchTerm: string
}

const CountrySelector: React.FC<CountrySelectorProps> = ({ onSelect, searchTerm }) => {
  const [inputValue, setInputValue] = useState(searchTerm || '')
  const [filteredCountries, setFilteredCountries] = useState<Country[]>([])
  const [showDropdown, setShowDropdown] = useState(false)
  const [selectedCountry, setSelectedCountry] = useState<Country | null>(null)

  const clearCountry = () => {
    setInputValue('')
    setSelectedCountry(null)
    setFilteredCountries([])
    setShowDropdown(false)
    onSelect(undefined)
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setInputValue(value)

    if (value.length > 0) {
      const filtered = Object.values(countries).filter(
        (country: Country) =>
          ![
            'af',
            'cf',
            'cu',
            'er',
            'ir',
            'kp',
            'ly',
            'mm',
            'so',
            'ss',
            'sd',
            'ru',
            'sy',
            've',
            'ye',
          ].includes(country.value.toLowerCase()) &&
          (country.label.toLowerCase().includes(value.toLowerCase()) ||
            country.value.toLowerCase().includes(value.toLowerCase())),
      )
      setFilteredCountries(filtered)
      setShowDropdown(true)
    } else {
      setFilteredCountries([])
      setShowDropdown(false)
    }
  }

  const handleSelectCountry = (country: Country) => {
    setSelectedCountry(country)
    setInputValue(country.label)
    setFilteredCountries([])
    setShowDropdown(false)
    onSelect(country.label) // Pass the selected country back to the parent component
  }

  return (
    <div style={{ position: 'relative' }}>
      <input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        placeholder={'Start typing to select a Country'}
        style={{
          width: '100%',
          maxWidth: '400px',
          minWidth: '350px',
          height: '40px',
          backgroundColor: '#ddd',
          border: '0px solid #333',
          paddingLeft: '10px',
          position: 'relative',
        }}
        onFocus={() => {
          if (inputValue.length > 0 && filteredCountries.length > 0) {
            setShowDropdown(true)
          }
        }}
      />
      <Search
        style={{
          position: 'absolute',
          right: '10px',
          top: '10px',
          width: '20px',
          height: '20px',
          fill: '#333',
        }}
      />
      {showDropdown && filteredCountries.length > 0 && (
        <ul
          style={{
            position: 'absolute',
            zIndex: 1,
            backgroundColor: 'white',
            listStyle: 'none',
            padding: 0,
            margin: 0,
            border: '1px solid #ccc',
            width: '100%',
            maxHeight: '150px',
            overflowY: 'auto',
          }}
        >
          {filteredCountries.map((country) => (
            <li
              key={country.value}
              onClick={() => handleSelectCountry(country)}
              style={{ padding: '8px', cursor: 'pointer' }}
            >
              {country.label} ({country.value})
            </li>
          ))}
        </ul>
      )}
      {selectedCountry && (
        <div
          style={{
            margin: '32px auto 0px',
            textAlign: 'center',
            maxWidth: '500px',
            position: window.innerWidth > 960 ? 'absolute' : 'relative',
            marginLeft: window.innerWidth > 960 ? '-50%' : 'auto',
          }}
        >
          <Typography variant="h4">
            <div style={{ marginRight: '8px', float: 'left' }}>{selectedCountry.label}</div>
            <Box style={{ marginRight: '8px', float: 'left' }}>
              <FlagIcon code={selectedCountry.value} size="24px" />
            </Box>
            <Box style={{ marginRight: '8px', float: 'left' }}>
              <Typography
                style={{
                  marginRight: '8px',
                  float: 'left',
                  fontSize: '0.9rem',
                  marginTop: '5px',
                  cursor: 'pointer',
                }}
                onClick={clearCountry}
              >
                <u>{'Clear'}</u>
              </Typography>
            </Box>
          </Typography>
        </div>
      )}
    </div>
  )
}

type SearchFieldProps = {
  search: boolean
  searchTerm: string
  setSearchTerm: (term: string) => void
}

const SearchField: FC<SearchFieldProps> = ({ search, searchTerm, setSearchTerm }) => {
  const classes = useStyles()
  const handleCountrySelect = (country: Country | undefined) => {
    setSearchTerm(`${country || ''}`)
  }

  return (
    <Box>
      <Grid item xs={12} container className={classes.searchWrapper}>
        <CountrySelector onSelect={handleCountrySelect} searchTerm={searchTerm} />
      </Grid>
    </Box>
  )
}

type CapabilitiesTableProps = {
  search?: boolean
}

export const CapabilitiesTable: FC<CapabilitiesTableProps> = ({ search = false }) => {
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation()

  const [category, subcategoryArg, highlightArg] = window.location.pathname.split('/').slice(2)

  const highlight = highlightArg === 'direct-debit' ? 'SEPA Direct Debit' : highlightArg

  const [selectedCategory, setSelectedCategory] = useState<CapabilityCategoryType>(
    categoryEnum(category) || CapabilityCategoryType.IncomingTransactional,
  )

  let subcategory = subcategoryArg

  if (!subcategory) {
    switch (selectedCategory) {
      case CapabilityCategoryType.AcceptPayments:
        subcategory = subcategoryString(
          selectedCategory,
          CapabilityAcceptPaymentsSubcategoryType.AcceptPaymentsApms,
        )
        break
      case CapabilityCategoryType.IncomingTransactional:
        subcategory = subcategoryString(
          selectedCategory,
          CapabilityIncomingTransactionalSubcategoryType.AccountCurrencies,
        )
        break
      case CapabilityCategoryType.OutgoingPayments:
        subcategory = subcategoryString(
          selectedCategory,
          CapabilityOutgoingPaymentsSubcategoryType.InternationalWires,
        )
        break
    }
  }

  const initialSubcategory =
    subcategoryEnum(selectedCategory, subcategory) ||
    CapabilityIncomingTransactionalSubcategoryType.AccountCurrencies

  const [selectedSubcategory, setSelectedSubcategory] = useState<CapabilitySubcategory>(
    initialSubcategory,
  )

  useEffect(() => {
    setSelectedSubcategory(initialSubcategory)
  }, [initialSubcategory])

  const buttonHandler = useCallback(
    (route) => {
      const scrollPosition = window.scrollY || window.pageYOffset
      history.replace(route)
      setTimeout(() => {
        window.scrollTo(0, scrollPosition)
      }, 0)
    },
    [history],
  )

  const [searchTerm, setSearchTerm] = useState<string>('')

  let capability: Capability

  switch (selectedCategory) {
    case CapabilityCategoryType.AcceptPayments:
      switch (selectedSubcategory) {
        case CapabilityAcceptPaymentsSubcategoryType.AcceptPaymentsBankTransfers:
          capability = capabilities.incomingMerchantPaymentsBankTransfers
          break
        case CapabilityAcceptPaymentsSubcategoryType.AcceptPaymentsApms:
          capability = capabilities.incomingMerchantPaymentsApms
          break
        case CapabilityAcceptPaymentsSubcategoryType.AcceptPaymentsCards:
          capability = capabilities.incomingMerchantPaymentsCards
          break
        default:
          capability = capabilities.incomingMerchantPaymentsApms
      }
      break
    case CapabilityCategoryType.IncomingTransactional:
      switch (selectedSubcategory) {
        case CapabilityIncomingTransactionalSubcategoryType.AccountCurrencies:
          capability = capabilities.accountCurrencies
          break
        default:
          capability = capabilities.accountCurrencies
      }
      break
    case CapabilityCategoryType.OutgoingPayments:
      switch (selectedSubcategory) {
        // case CapabilityOutgoingPaymentsSubcategoryType.InstantBankTransfers:
        //   capability = capabilities.outgoingInstantBankTransfers
        //   break
        case CapabilityOutgoingPaymentsSubcategoryType.InternationalWires:
          capability = capabilities.outgoingInternationalWires
          break
        case CapabilityOutgoingPaymentsSubcategoryType.LocalAch:
          capability = capabilities.outgoingLocalAch
          break
        case CapabilityOutgoingPaymentsSubcategoryType.WalletsInstant:
          capability = capabilities.outboundWalletsInstant
          break
        // case CapabilityOutgoingPaymentsSubcategoryType.PushToCard:
        //   capability = capabilities.outboundPushToCard
        //   break
        default:
          capability = capabilities.outgoingInternationalWires
      }
      break
  }

  const filteredCapability: Capability = Object.assign({}, capability, {})

  if (searchTerm) {
    filteredCapability['data'] = capability.data.filter((row: any) => {
      return (
        row.region?.toLowerCase() === searchTerm.toLowerCase() ||
        row.region?.toLowerCase() === 'worldwide' ||
        searchTerm.trim() === ''
      )
    })
  }

  return (
    <>
      {search && (
        <Box mt={8}>
          <Grid container>
            <Grid item xs={12} className={classes.textCenter}>
              <Grid container justifyContent={'center'}>
                <Grid item xs={12} md={5}>
                  <Typography variant="h4" className={classes.textWidth}>
                    {t('Capabilities-By-Country')}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={5}>
                  <SearchField
                    setSearchTerm={setSearchTerm}
                    searchTerm={searchTerm}
                    search={search}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}
      <Box mb={3} mt={3}>
        <Grid
          item
          xs={12}
          container
          justifyContent={'center'}
          className={clsx(classes.horizontalScrollerWrapper, classes.horizontalScrollerWrapper1)}
        >
          <div className={clsx(classes.horizontalScroller, classes.horizontalScroller1)}>
            <CategoryMenuItem
              selectedCategory={selectedCategory}
              elementCategory={CapabilityCategoryType.AcceptPayments}
              stringElement={'Capabilities-Incoming-Merchant'}
              setSelectedCategory={setSelectedCategory}
              clickHandler={buttonHandler}
              search={search}
            />
            <CategoryMenuItem
              selectedCategory={selectedCategory}
              elementCategory={CapabilityCategoryType.IncomingTransactional}
              stringElement={'Capabilities-Incoming-Transactional'}
              setSelectedCategory={setSelectedCategory}
              clickHandler={buttonHandler}
              search={search}
            />
            <CategoryMenuItem
              selectedCategory={selectedCategory}
              elementCategory={CapabilityCategoryType.OutgoingPayments}
              stringElement={'Capabilities-Outgoing-Payments'}
              setSelectedCategory={setSelectedCategory}
              clickHandler={buttonHandler}
              search={search}
            />
          </div>
        </Grid>
      </Box>
      <Box mb={2} mt={2}>
        {selectedCategory === CapabilityCategoryType.AcceptPayments && (
          <Grid
            item
            xs={12}
            container
            justifyContent={'center'}
            className={clsx(classes.horizontalScrollerWrapper, classes.horizontalScrollerWrapper2)}
          >
            <div className={clsx(classes.horizontalScroller, classes.horizontalScroller2)}>
              <SubcategoryMenuItem
                selectedSubcategory={selectedSubcategory}
                elementCategory={CapabilityCategoryType.AcceptPayments}
                elementSubcategory={
                  CapabilityAcceptPaymentsSubcategoryType.AcceptPaymentsBankTransfers
                }
                stringElement={'Capabilities-Incoming-Merchant-Payments-Bank-Transfers'}
                setSelectedSubcategory={setSelectedSubcategory}
                clickHandler={buttonHandler}
                search={search}
              />
              <SubcategoryMenuItem
                selectedSubcategory={selectedSubcategory}
                elementCategory={CapabilityCategoryType.AcceptPayments}
                elementSubcategory={CapabilityAcceptPaymentsSubcategoryType.AcceptPaymentsCards}
                stringElement={'Capabilities-Incoming-Merchant-Payments-Cards'}
                setSelectedSubcategory={setSelectedSubcategory}
                clickHandler={buttonHandler}
                search={search}
              />
              <SubcategoryMenuItem
                selectedSubcategory={selectedSubcategory}
                elementCategory={CapabilityCategoryType.AcceptPayments}
                elementSubcategory={CapabilityAcceptPaymentsSubcategoryType.AcceptPaymentsApms}
                stringElement={'Capabilities-Incoming-Merchant-Payments-Apms'}
                setSelectedSubcategory={setSelectedSubcategory}
                clickHandler={buttonHandler}
                search={search}
              />
            </div>
          </Grid>
        )}
        {selectedCategory === CapabilityCategoryType.IncomingTransactional && (
          <Grid
            item
            xs={12}
            container
            justifyContent={'center'}
            className={clsx(classes.horizontalScrollerWrapper, classes.horizontalScrollerWrapper3)}
          >
            <div className={clsx(classes.horizontalScroller, classes.horizontalScroller3)}>
              <SubcategoryMenuItem
                selectedSubcategory={selectedSubcategory}
                elementCategory={CapabilityCategoryType.IncomingTransactional}
                elementSubcategory={
                  CapabilityIncomingTransactionalSubcategoryType.AccountCurrencies
                }
                stringElement={'Capabilities-Incoming-Transactionsal-Account-Currencies'}
                setSelectedSubcategory={setSelectedSubcategory}
                clickHandler={buttonHandler}
                search={search}
              />
            </div>
          </Grid>
        )}
        {selectedCategory === CapabilityCategoryType.OutgoingPayments && (
          <Grid
            item
            xs={12}
            container
            justifyContent={'center'}
            className={clsx(classes.horizontalScrollerWrapper, classes.horizontalScrollerWrapper4)}
          >
            <div className={clsx(classes.horizontalScroller, classes.horizontalScroller4)}>
              {/* <SubcategoryMenuItem
                selectedSubcategory={selectedSubcategory}
                elementCategory={CapabilityCategoryType.OutgoingPayments}
                elementSubcategory={CapabilityOutgoingPaymentsSubcategoryType.InstantBankTransfers}
                stringElement={'Capabilities-Outgoing-Payments-Instant-Bank-Transfers'}
                setSelectedSubcategory={setSelectedSubcategory}
                clickHandler={buttonHandler}
                search={search}
              /> */}
              <SubcategoryMenuItem
                selectedSubcategory={selectedSubcategory}
                elementCategory={CapabilityCategoryType.OutgoingPayments}
                elementSubcategory={CapabilityOutgoingPaymentsSubcategoryType.InternationalWires}
                stringElement={'Capabilities-Outgoing-Payments-International-Wires'}
                setSelectedSubcategory={setSelectedSubcategory}
                clickHandler={buttonHandler}
                search={search}
              />
              <SubcategoryMenuItem
                selectedSubcategory={selectedSubcategory}
                elementCategory={CapabilityCategoryType.OutgoingPayments}
                elementSubcategory={CapabilityOutgoingPaymentsSubcategoryType.LocalAch}
                stringElement={'Capabilities-Outgoing-Payments-Local-ACH'}
                setSelectedSubcategory={setSelectedSubcategory}
                clickHandler={buttonHandler}
                search={search}
              />
              <SubcategoryMenuItem
                selectedSubcategory={selectedSubcategory}
                elementCategory={CapabilityCategoryType.OutgoingPayments}
                elementSubcategory={CapabilityOutgoingPaymentsSubcategoryType.WalletsInstant}
                stringElement={'Capabilities-Outgoing-Payments-Wallets-Instant'}
                setSelectedSubcategory={setSelectedSubcategory}
                clickHandler={buttonHandler}
                search={search}
              />
              {/* <SubcategoryMenuItem
                selectedSubcategory={selectedSubcategory}
                elementCategory={CapabilityCategoryType.OutgoingPayments}
                elementSubcategory={CapabilityOutgoingPaymentsSubcategoryType.PushToCard}
                stringElement={'Capabilities-Outgoing-Payments-Push-To-Card'}
                setSelectedSubcategory={setSelectedSubcategory}
                clickHandler={buttonHandler}
                search={search}
              /> */}
            </div>
          </Grid>
        )}
      </Box>
      <Box
        mb={12}
        mt={12}
        className={clsx(classes.table, classes.horizontalScrollerWrapper, classes.tableMobileWidth)}
      >
        <Grid container spacing={6}>
          <CapabilitiesDataTable
            capability={filteredCapability as Capability}
            highlight={highlight}
          />
        </Grid>
      </Box>
    </>
  )
}
