const accountCurrencies = {
  type: 'account_currencies',
  sticky_note: 'More currencies available upon request.<br/>*Restrictions may apply.',
  visible_columns: ['currency', 'iban', 'virtual_iban'],
  hidden_columns: ['region'],
  column_names: {
    currency: '16',
    iban: '21',
    virtual_iban: '22',
  },
  data: [
    {
      currency: 'EUR',
      iban: 'Swiss (CH)',
      virtual_iban: 'Upon Request',
      region: 'Worldwide',
    },
    {
      currency: 'USD',
      iban: 'Swiss (CH)',
      virtual_iban: 'Upon Request',
      region: 'Worldwide',
    },
    {
      currency: 'GBP',
      iban: 'Swiss (CH)',
      virtual_iban: 'Upon Request',
      region: 'Worldwide',
    },
    {
      currency: 'CHF',
      iban: 'Swiss (CH)',
      virtual_iban: 'Upon Request',
      region: 'Worldwide',
    },
    {
      currency: 'JPY',
      iban: 'Swiss (CH)',
      virtual_iban: 'Upon Request',
      region: 'Worldwide',
    },
    {
      currency: 'DKK',
      iban: 'Swiss (CH)',
      virtual_iban: 'Upon Request',
      region: 'Worldwide',
    },
    {
      currency: 'SEK',
      iban: 'Swiss (CH)',
      virtual_iban: 'Upon Request',
      region: 'Worldwide',
    },
    {
      currency: 'NOK',
      iban: 'Swiss (CH)',
      virtual_iban: 'Upon Request',
      region: 'Worldwide',
    },
    {
      currency: 'TRY',
      iban: 'Swiss (CH)',
      virtual_iban: 'Upon Request',
      region: 'Worldwide',
    },
    {
      currency: 'AED',
      iban: 'Swiss (CH)',
      virtual_iban: 'Upon Request',
      region: 'Worldwide',
    },
    {
      currency: 'AUD',
      iban: 'Swiss (CH)',
      virtual_iban: 'Upon Request',
      region: 'Worldwide',
    },
    {
      currency: 'CAD',
      iban: 'Swiss (CH)',
      virtual_iban: 'Upon Request',
      region: 'Worldwide',
    },
    {
      currency: 'PLN',
      iban: 'Swiss (CH)',
      virtual_iban: 'Upon Request',
      region: 'Worldwide',
    },
    {
      currency: 'ZAR',
      iban: 'Swiss (CH)',
      virtual_iban: 'Upon Request',
      region: 'Worldwide',
    },
    {
      currency: 'HKD',
      iban: 'Swiss (CH)',
      virtual_iban: 'Upon Request',
      region: 'Worldwide',
    },
    {
      currency: 'HUF',
      iban: 'Swiss (CH)',
      virtual_iban: 'Upon Request',
      region: 'Worldwide',
    },
    {
      currency: 'MXN',
      iban: 'Swiss (CH)',
      virtual_iban: 'Upon Request',
      region: 'Worldwide',
    },
  ],
}

const exportedObject = accountCurrencies

export default exportedObject
