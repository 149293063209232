import React, { FC, useCallback } from 'react'
import clsx from 'clsx'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'

import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  blackBg: {
    backgroundColor: theme.palette.primary.main,
    maxWidth: '100% !important',
    color: 'white',
    padding: theme.spacing(12),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4),
    },
  },
  rowTitle: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
      textAlign: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.5rem',
    },
  },
  rowTitleWhite: {
    color: 'white',
    fontSize: '30px',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
    },
  },
  rowSubtitle: {
    fontSize: '16px',
    marginTop: theme.spacing(3),
  },
  buttonContainer: {
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      width: '100%',
    },
  },
  getStartedButton: {
    fontWeight: 700,
    minWidth: 200,
    border: '1px solid white',
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  signUpButton: {
    fontWeight: 700,
    minWidth: 200,
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(0),
      marginTop: theme.spacing(2),
      width: '100%',
    },
  },
}))

export const SubSubFooterV4: FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()

  const signupHandler = useCallback(() => {
    window.location.href = 'https://dashboard.bivial.com/signup'
  }, [history])

  return (
    <>
      <Container className={classes.blackBg}>
        <Box>
          <Grid container direction={'column'} alignItems="center">
            <Grid item xs={12} md={4}>
              <Typography
                variant={'h2'}
                align="center"
                gutterBottom
                className={clsx(classes.rowTitle, classes.rowTitleWhite)}
              >
                {t('COMMON-CTA4-Title')}
              </Typography>
            </Grid>

            <Box mt={4} className={classes.buttonContainer}>
              <Button
                size="medium"
                variant="contained"
                color="secondary"
                onClick={signupHandler}
                className={classes.signUpButton}
              >
                {t('COMMON-CTA-GetStarted')}
              </Button>
            </Box>
          </Grid>
        </Box>
      </Container>
    </>
  )
}
