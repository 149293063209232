import React, { FC, PropsWithChildren, useCallback, useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import CardMedia from '@material-ui/core/CardMedia'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import clsx from 'clsx'
import { useHistory, useParams } from 'react-router'
import { useTranslation } from 'react-i18next'
import newsPages, { NewsType } from './newsPages'
import pressReferences from './pressPages'
import {
  MAX_ENTRIES_PER_PAGE,
  MIN_WIDTH_FOR_NEWS,
  PATH_NEWS_ENTRIES,
  PATH_NEWS_PAGES_CATEGORY_PAGES,
} from '../../constants'
import IconArrow from '../../assets/icons/icon-arrow.svg?react'
import IconPageDots from '../../assets/icons/icon-page-dots.svg?react'
import IconPageLeftSingle from '../../assets/icons/icon-page-left-single.svg?react'
import IconPageLeftDouble from '../../assets/icons/icon-page-left-double.svg?react'
import IconPageRightSingle from '../../assets/icons/icon-page-right-single.svg?react'
import IconPageRightDouble from '../../assets/icons/icon-page-right-double.svg?react'
import { tagManagerHandler } from '../../utils'
import { IPageLink } from '../../types'
import { Hidden } from '@material-ui/core'
import { WebsiteTitle } from '../Common'

const useStyles = makeStyles((theme) => ({
  heroImage: {
    maxWidth: '100%',
    backgroundRepeat: 'no-Repeat',
    backgroundPosition: 'center',
    color: '#fff',
    position: 'relative',
    backgroundSize: 'cover',
  },
  heroContentPrefHeader: {
    marginBottom: theme.spacing(5),
  },
  readMoreWrapper: {
    textAlign: 'end',
    marginTop: theme.spacing(3),
  },
  readMoreButton: {
    width: 130,
    background: 'transparent',
    color: theme.palette.primary.main,
    fontWeight: 700,
    boxShadow: 'none',
    marginLeft: '-16px',
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.primary.main,
      boxShadow: 'none',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  mrgbtm: {
    marginBottom: theme.spacing(12),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(4),
    },
  },
  grayBg: {
    backgroundColor: '#f8f8f8',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 4),
      '& .MuiCardMedia-img': {
        width: '50%',
        display: 'inline-block',
      },
    },
  },
  maxWidth: {
    maxWidth: '1000px',
    width: '100%',
    margin: '0 auto',
  },
  row: {
    marginTop: theme.spacing(0),
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(4),
      textAlign: 'center',
    },
  },
  extraMargin: {
    marginTop: theme.spacing(12),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(4),
    },
  },
  pageTitle: {
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(1),
      fontSize: '2.25rem',
    },
  },
  rowTitle: {
    fontSize: '2rem',
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem',
    },
  },
  rowTitle2: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
  },
  rowSubtitle: {
    marginTop: theme.spacing(5),
    color: '#555',
  },
  rowText: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  item1: {
    order: 1,
    [theme.breakpoints.up('md')]: {
      order: 1,
    },
  },
  item2: {
    order: 3,
    [theme.breakpoints.up('md')]: {
      order: 2,
    },
  },
  item3: {
    order: 2,
    [theme.breakpoints.up('md')]: {
      order: 3,
    },
  },
  newsMenu: {
    float: 'left',
    marginRight: theme.spacing(15),
    marginTop: theme.spacing(4),
    paddingBottom: theme.spacing(1),
    fontSize: '1rem',
    fontWeight: 700,
    cursor: 'pointer',
    '&:last-child': {
      marginRight: theme.spacing(0),
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(2),
      '&:last-child': {
        marginRight: theme.spacing(0),
      },
    },
  },
  selected: {
    borderBottom: '4px solid #ef2828',
  },
  pageLink: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#a71c1c',
      color: 'white',
    },
  },
  pageLinkCurrent: {
    backgroundColor: '#ef2828',
    color: 'white',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: '#ef2828',
    },
  },
  enabledPaging: {
    cursor: 'pointer',
  },
  disabledPaging: {
    opacity: 0.5,
  },
  horizontalScrollerWrapper: {
    [theme.breakpoints.down('sm')]: {
      overflowX: 'auto',
      paddingLeft: '135px',
      msOverflowStyle: 'none',
      scrollbarWidth: 'none',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
  },
  horizontalScroller: {
    position: 'relative',
    minWidth: '450px',
  },
  pressItemsWrapper: {
    marginTop: theme.spacing(8),
    display: 'block',
    minHeight: '260px',
    textAlign: 'center',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      minHeight: '540px',
      padding: '0px',
    },
  },
  pressItem: {
    width: '12%',
    margin: '2%',
    float: 'left',
    [theme.breakpoints.down('sm')]: {
      width: '50%',
      margin: '10px 0px',
    },
  },
  rowSubtitle2: {
    // marginTop: theme.spacing(5),
    color: '#555',
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(3),
    },
  },
  newsTextWrapper: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      height: '100%',
    },
  },
  newsText: {
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(8),
    },
  },
}))

const PageLink: FC<IPageLink & PropsWithChildren<any>> = ({ children, onClick, current }) => {
  const classes = useStyles()
  return (
    <Box
      width={48}
      height={48}
      textAlign={'center'}
      paddingTop={'15px'}
      marginTop={'-6px'}
      onClick={onClick}
      className={clsx(classes.pageLink, current && classes.pageLinkCurrent)}
    >
      {children}
    </Box>
  )
}

const categoryString = (category: NewsType): string => {
  switch (category) {
    case NewsType.Latest:
      return 'latest'
    case NewsType.News:
      return 'company'
    case NewsType.Article:
      return 'articles'
    case NewsType.Events:
      return 'events'
    case NewsType.Press:
      return 'press'
    default:
      return 'latest'
  }
}

const categoryEnum = (category: string): NewsType => {
  switch (category) {
    case 'latest':
      return NewsType.Latest
    case 'company':
      return NewsType.News
    case 'articles':
      return NewsType.Article
    case 'events':
      return NewsType.Events
    case 'press':
      return NewsType.Press
    default:
      return NewsType.Latest
  }
}

type NewsParams = {
  category: string
  page: string
}

export const NewsAndMediaPage: FC = () => {
  const classes = useStyles()
  const history = useHistory()
  const { category, page } = useParams<NewsParams>()
  const { t } = useTranslation()

  const [selectedCategory, setSelectedCategory] = useState<NewsType>(
    categoryEnum(category) || NewsType.Latest,
  )
  const [selectedPage, setSelectedPage] = useState<number>(Number.parseInt(page) - 1 || 0)
  const [width, setWidth] = useState<number>(window.innerWidth)

  const mobileView = width < MIN_WIDTH_FOR_NEWS

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
  })

  const buttonHandler = useCallback(
    (route) => {
      history.push(route)
    },
    [history],
  )

  const filteredNewsPages = newsPages.filter((f) => !f.hidden)

  const filteredNewsPrePage =
    selectedCategory === NewsType.Latest
      ? filteredNewsPages.filter((news) => news.type !== NewsType.Events)
      : filteredNewsPages.filter((news) => news.type === selectedCategory)

  const pages = Math.ceil(filteredNewsPrePage.length / MAX_ENTRIES_PER_PAGE)

  const pageLinks = []

  for (let i = 0; i <= pages - 1; i++) {
    pageLinks.push(
      <PageLink
        onClick={() => {
          setSelectedPage(i)
          buttonHandler(
            PATH_NEWS_PAGES_CATEGORY_PAGES.replace(
              ':category',
              categoryString(selectedCategory),
            ).replace(':page', `${i + 1}`),
          )
        }}
        current={selectedPage === i}
      >
        {i + 1}
      </PageLink>,
    )
  }

  const filteredNews = mobileView
    ? filteredNewsPrePage
    : filteredNewsPrePage.splice(selectedPage * MAX_ENTRIES_PER_PAGE, MAX_ENTRIES_PER_PAGE)

  const canPageLeft = selectedPage >= 1
  const canPageRight = selectedPage < pages - 1

  tagManagerHandler('PageNewsAndMedia')

  return (
    <>
      <Container>
        <Grid item xs={12} className={classes.maxWidth}>
          <Box className={classes.row}>
            <WebsiteTitle title={'News-And-Media'} />
            <Grid
              item
              xs={12}
              container
              justifyContent={'center'}
              className={classes.horizontalScrollerWrapper}
            >
              <div className={classes.horizontalScroller}>
                <Typography
                  className={clsx(
                    classes.newsMenu,
                    selectedCategory === NewsType.Latest && classes.selected,
                  )}
                  onClick={() => {
                    setSelectedPage(0)
                    setSelectedCategory(NewsType.Latest)
                    buttonHandler(
                      PATH_NEWS_PAGES_CATEGORY_PAGES.replace(
                        ':category',
                        categoryString(NewsType.Latest),
                      ).replace(':page', `${mobileView ? '' : 1}`),
                    )
                  }}
                >
                  {t('News-Latest')}
                </Typography>
                <Typography
                  className={clsx(
                    classes.newsMenu,
                    selectedCategory === NewsType.News && classes.selected,
                  )}
                  onClick={() => {
                    setSelectedPage(0)
                    setSelectedCategory(NewsType.News)
                    buttonHandler(
                      PATH_NEWS_PAGES_CATEGORY_PAGES.replace(
                        ':category',
                        categoryString(NewsType.News),
                      ).replace(':page', `${mobileView ? '' : 1}`),
                    )
                  }}
                >
                  {t('News-Company-News')}
                </Typography>
                <Typography
                  className={clsx(
                    classes.newsMenu,
                    selectedCategory === NewsType.Article && classes.selected,
                  )}
                  onClick={() => {
                    setSelectedPage(0)
                    setSelectedCategory(NewsType.Article)
                    buttonHandler(
                      PATH_NEWS_PAGES_CATEGORY_PAGES.replace(
                        ':category',
                        categoryString(NewsType.Article),
                      ).replace(':page', `${mobileView ? '' : 1}`),
                    )
                  }}
                >
                  {t('News-Articles')}
                </Typography>
                <Typography
                  className={clsx(
                    classes.newsMenu,
                    selectedCategory === NewsType.Events && classes.selected,
                  )}
                  onClick={() => {
                    setSelectedPage(0)
                    setSelectedCategory(NewsType.Events)
                    buttonHandler(
                      PATH_NEWS_PAGES_CATEGORY_PAGES.replace(
                        ':category',
                        categoryString(NewsType.Events),
                      ).replace(':page', `${mobileView ? '' : 1}`),
                    )
                  }}
                >
                  {t('News-Events')}
                </Typography>
                <Typography
                  className={clsx(
                    classes.newsMenu,
                    selectedCategory === NewsType.Press && classes.selected,
                  )}
                  onClick={() => {
                    setSelectedPage(0)
                    setSelectedCategory(NewsType.Press)
                    buttonHandler(
                      PATH_NEWS_PAGES_CATEGORY_PAGES.replace(
                        ':category',
                        categoryString(NewsType.Press),
                      ).replace(':page', `${mobileView ? '' : 1}`),
                    )
                  }}
                >
                  {t('News-In-The-Press')}
                </Typography>
              </div>
            </Grid>
          </Box>
          {selectedCategory === NewsType.Press && (
            <Box className={clsx(classes.row, classes.grayBg, classes.pressItemsWrapper)}>
              {pressReferences.map((pressRef: any, i: number) => (
                <div key={`press-${i}`} className={classes.pressItem}>
                  <a href={pressRef.url} target="_blank" rel="noreferrer">
                    <CardMedia
                      component="img"
                      alt={pressRef.name}
                      height="100%"
                      image={pressRef.img}
                    />
                  </a>
                </div>
              ))}
            </Box>
          )}
          {selectedCategory !== NewsType.Press &&
            selectedCategory !== NewsType.Events &&
            filteredNews.splice(0, 1).map((page: any, i: number) => (
              <Box key={0} mt={9} mb={6}>
                <Grid container>
                  <Grid item xs={12} md={12}>
                    <CardMedia component="img" alt="" height="100%" image={page.image} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant={'h2'} className={classes.rowTitle}>
                      <div dangerouslySetInnerHTML={{ __html: t(page.title) + '.' }} />
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6} className={classes.readMoreWrapper}>
                    <Button
                      className={classes.readMoreButton}
                      size="medium"
                      variant="contained"
                      onClick={() =>
                        buttonHandler(PATH_NEWS_ENTRIES.replace(':id', page.permalink))
                      }
                      endIcon={<IconArrow />}
                    >
                      {t('News-Read-More')}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            ))}
          <Grid container spacing={4}>
            {selectedCategory !== NewsType.Press &&
              selectedCategory !== NewsType.Events &&
              filteredNews.map((page: any, i: number) => (
                <Grid key={i} item xs={12} md={4} container>
                  <Box className={clsx(classes.row)}>
                    <Grid item xs={12}>
                      <CardMedia component="img" alt="" height="100%" image={page.image} />
                    </Grid>
                    <Grid className={clsx(classes.item2, classes.rowText)} item xs={12}>
                      <Typography className={clsx(classes.rowTitle, classes.rowTitle2)}>
                        {t(page.title)}.
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        className={classes.readMoreButton}
                        size="medium"
                        variant="contained"
                        onClick={() =>
                          buttonHandler(PATH_NEWS_ENTRIES.replace(':id', page.permalink))
                        }
                        endIcon={<IconArrow />}
                      >
                        {t('News-Read-More')}
                      </Button>
                    </Grid>
                  </Box>
                </Grid>
              ))}
          </Grid>
          {selectedCategory === NewsType.Events &&
            filteredNews.splice(0, 1).map((page: any, i: number) => (
              <Box key={0} mt={9} mb={6}>
                <Grid container>
                  <Grid item xs={12} md={5}>
                    <CardMedia component="img" alt="" height="100%" image={page.image} />
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <Box className={classes.newsTextWrapper}>
                      <Box className={classes.newsText}>
                        <Typography variant={'h2'} className={classes.rowTitle}>
                          {t(page.title)}.
                        </Typography>
                        <br />
                        <Typography className={classes.rowSubtitle2}>
                          {t(page?.place)}
                          {t(page?.date).length > 0 && ' • '}
                          {t(page?.date)}
                        </Typography>
                        <br />
                        <Typography className={classes.rowSubtitle2}>{t(page?.summary)}</Typography>
                        <br />
                        <Button
                          className={classes.readMoreButton}
                          size="medium"
                          variant="contained"
                          onClick={() =>
                            buttonHandler(PATH_NEWS_ENTRIES.replace(':id', page.permalink))
                          }
                          endIcon={<IconArrow />}
                        >
                          {t('News-Read-More')}
                        </Button>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            ))}
          <Grid container spacing={4}>
            {selectedCategory === NewsType.Events &&
              filteredNews.map((page: any, i: number) => (
                <Grid key={i} item xs={12} md={4} container>
                  <Box className={clsx(classes.row)}>
                    <Grid item xs={12}>
                      <CardMedia component="img" alt="" height="100%" image={page.image} />
                    </Grid>
                    <Grid className={clsx(classes.item2, classes.rowText)} item xs={12}>
                      <Typography className={clsx(classes.rowTitle, classes.rowTitle2)}>
                        {t(page.title)}. {t(page?.summary).substring(0, 50)}...
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        className={classes.readMoreButton}
                        size="medium"
                        variant="contained"
                        onClick={() =>
                          buttonHandler(PATH_NEWS_ENTRIES.replace(':id', page.permalink))
                        }
                        endIcon={<IconArrow />}
                      >
                        {t('News-Read-More')}
                      </Button>
                    </Grid>
                  </Box>
                </Grid>
              ))}
          </Grid>
          <Hidden smDown>
            {selectedCategory !== NewsType.Press && (
              <Box mt={6}>
                <Grid item xs={12} container justifyContent={'center'}>
                  <Box
                    className={clsx(canPageLeft ? classes.enabledPaging : classes.disabledPaging)}
                  >
                    <IconPageLeftDouble
                      onClick={() => {
                        if (canPageLeft) {
                          setSelectedPage(0)
                          buttonHandler(
                            PATH_NEWS_PAGES_CATEGORY_PAGES.replace(
                              ':category',
                              categoryString(selectedCategory),
                            ).replace(':page', `${1}`),
                          )
                        }
                      }}
                    />
                  </Box>
                  <Box
                    className={clsx(canPageLeft ? classes.enabledPaging : classes.disabledPaging)}
                  >
                    <IconPageLeftSingle
                      onClick={() => {
                        if (canPageLeft) {
                          setSelectedPage(selectedPage - 1)
                          buttonHandler(
                            PATH_NEWS_PAGES_CATEGORY_PAGES.replace(
                              ':category',
                              categoryString(selectedCategory),
                            ).replace(':page', `${selectedPage}`),
                          )
                        }
                      }}
                    />
                  </Box>
                  {pageLinks.map((l, i) => l)}
                  {pages > 6 && <IconPageDots />}
                  <Box
                    className={clsx(canPageRight ? classes.enabledPaging : classes.disabledPaging)}
                  >
                    <IconPageRightSingle
                      onClick={() => {
                        if (canPageRight) {
                          setSelectedPage(selectedPage + 1)
                          buttonHandler(
                            PATH_NEWS_PAGES_CATEGORY_PAGES.replace(
                              ':category',
                              categoryString(selectedCategory),
                            ).replace(':page', `${selectedPage + 2}`),
                          )
                        }
                      }}
                    />
                  </Box>
                  <Box
                    className={clsx(canPageRight ? classes.enabledPaging : classes.disabledPaging)}
                  >
                    <IconPageRightDouble
                      onClick={() => {
                        if (canPageRight) {
                          setSelectedPage(pages - 1)
                          buttonHandler(
                            PATH_NEWS_PAGES_CATEGORY_PAGES.replace(
                              ':category',
                              categoryString(selectedCategory),
                            ).replace(':page', `${pages}`),
                          )
                        }
                      }}
                    />
                  </Box>
                </Grid>
              </Box>
            )}
          </Hidden>
        </Grid>
      </Container>
    </>
  )
}
