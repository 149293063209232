import { FC, default as React, useCallback } from 'react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'

import {
  WebsiteTitle,
  SubSubFooter,
  HeroImage,
  FeatureElements,
  SectionTitle,
  SectionContent,
  FeatureBox,
  MediaObject,
} from '../Common'
import imgHero2x from '../../assets/images/img-about-1@2x.png'
import logo from '../../assets/images/logo.svg'
import img2 from '../../assets/images/about-us-bivial.png'
import icon1 from '../../assets/icons/img-about-1.svg'
import icon2 from '../../assets/icons/img-about-2.svg'
import icon3 from '../../assets/icons/img-about-3.svg'

import { tagManagerHandler } from '../../utils'
import { Button, CardMedia, Hidden, Typography } from '@material-ui/core'
import { PATH_BOARD_DIRECTORS, PATH_EXECUTIVE_MANAGEMENT } from '../../constants'

const useStyles = makeStyles((theme) => ({
  paddingBox: {
    padding: theme.spacing(2.5, 5),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1.5, 2),
    },
  },
  greyBg: {
    backgroundColor: '#ddd',
    padding: theme.spacing(4),
  },
  simpleFeatureContainer: {
    maxWidth: '1050px',
    margin: '0 auto',
    marginBottom: theme.spacing(6),
  },
  iconFeatureContainer: {
    maxWidth: '700px',
    margin: '0 auto',
    marginBottom: theme.spacing(6),
  },
  featureContainer: {
    maxWidth: '1100px',
    margin: '0 auto',
  },
  mediaObjectContainer: {
    marginTop: theme.spacing(12),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(6),
    },
  },
  centerSection: {
    maxWidth: '700px',
    margin: 'auto',
    '& > p': {
      textAlign: 'center',
    },
    '& > div > h2': {
      textAlign: 'center',
    },
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    width: '36px',
    height: 'auto',
  },
  verticalIcon: {
    transform: 'rotate(90deg)',
    marginTop: theme.spacing(4),
  },
  logoIconWrapper: {
    display: 'flex',
    padding: theme.spacing(10, 4, 8, 1),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
      textAlign: 'center',
    },
  },
  logoIcon: {
    width: 'auto',
    height: '24px',
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      margin: 'auto',
      marginBottom: theme.spacing(4),
    },
  },
  buttonContainer: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  getStartedButton: {
    fontWeight: 700,
    minWidth: 200,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  subtext: {
    fontSize: '0.95rem',
    marginLeft: '1px',
  },
  paddingLeft3: {
    paddingLeft: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(0),
      marginTop: theme.spacing(4),
    },
  },
  paddingLeft4: {
    paddingLeft: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(0),
      marginTop: theme.spacing(4),
    },
  },
  subLogo: {
    marginTop: theme.spacing(-1.5),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      margin: 'auto',
      marginTop: theme.spacing(-1),
      marginBottom: theme.spacing(4),
      textAlign: 'center',
    },
  },
}))

type FeatureElement = { title: string; content: string }
type BoxFeatureElement =
  | { type: 'icon' | 'title' | 'subtitle' | 'section' | 'content'; text: string }
  | { type: 'link'; text: string; linkTarget: string }
  | { type: 'separator' }
type AboutListItemProps = { content: string }

const AboutListItem: FC<AboutListItemProps> = ({ content }) => {
  const { t } = useTranslation()

  return (
    <Box mb={2}>
      <Typography>
        <div dangerouslySetInnerHTML={{ __html: t(content) }} />
      </Typography>
    </Box>
  )
}

const featuresA: FeatureElement[] = [
  {
    title: 'About-H21',
    content: 'About-P21',
  },
  {
    title: 'About-H22',
    content: 'About-P22',
  },
  {
    title: 'About-H23',
    content: 'About-P23',
  },
]

const featureB: BoxFeatureElement[] = [
  {
    type: 'icon',
    text: icon1,
  },
  {
    type: 'subtitle',
    text: 'About-H31',
  },
  {
    type: 'content',
    text: 'About-P31',
  },
  {
    type: 'icon',
    text: icon2,
  },
  {
    type: 'subtitle',
    text: 'About-H32',
  },
  {
    type: 'content',
    text: 'About-P32',
  },
  {
    type: 'separator',
  },
]

export const AboutPage: FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()

  const docHandler = useCallback(() => {
    window.location.href = 'https://dashboard.bivial.com/signup/partner'
  }, [])

  const boardHandler = useCallback(() => {
    history.push(PATH_BOARD_DIRECTORS)
  }, [history])

  const mgmtHandler = useCallback(() => {
    history.push(PATH_EXECUTIVE_MANAGEMENT)
  }, [history])

  tagManagerHandler('PageAbout')

  return (
    <>
      <Box className={classes.paddingBox}>
        <Container>
          <WebsiteTitle title={'About-H1'} />
          <HeroImage image={imgHero2x} />
          <Grid container>
            <Grid item xs={12} className={classes.simpleFeatureContainer}>
              <FeatureElements elements={featuresA} />
            </Grid>
            <Grid item xs={12}>
              <Box mb={6}>
                <Hidden mdUp>
                  <Box mt={8} />
                </Hidden>
                <Box className={classes.centerSection}>
                  <SectionTitle title={'About-H3'} />
                </Box>
              </Box>
            </Grid>
            <Grid container>
              <Grid item xs={12} className={classes.simpleFeatureContainer}>
                <Grid container spacing={4}>
                  <Grid item xs={12} md={5}>
                    <Grid container>
                      <Grid item xs={12}>
                        <FeatureBox isBlackBg={true} elements={featureB} />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Hidden smDown>
                    <Grid item xs={12} md={2} className={classes.iconWrapper}>
                      <CardMedia component="img" image={icon3} className={classes.icon} />
                    </Grid>
                  </Hidden>
                  <Hidden mdUp>
                    <Grid item xs={12} md={2} className={classes.iconWrapper}>
                      <CardMedia
                        component="img"
                        image={icon3}
                        className={clsx(classes.icon, classes.verticalIcon)}
                      />
                    </Grid>
                  </Hidden>
                  <Grid item xs={12} md={5}>
                    <Grid container>
                      <Grid item xs={12} className={classes.logoIconWrapper}>
                        <Grid container>
                          <Grid item xs={12}>
                            <CardMedia component="img" image={logo} className={classes.logoIcon} />
                          </Grid>
                          <Grid item xs={12}>
                            <Typography>{t('About-P33')}</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {/* <Grid container className={clsx(classes.simpleFeatureContainer, classes.greyBg)}>
              <Grid item xs={12} md={4}>
                <Box className={classes.paddingLeft4}>
                  <SectionTitle title={'About-H4'} />
                  <Box mt={4} mb={6} className={classes.buttonContainer}>
                    <Button
                      size="medium"
                      variant="contained"
                      color="primary"
                      onClick={docHandler}
                      className={classes.getStartedButton}
                    >
                      {t('COMMON-CTA-LearnMore')}
                    </Button>
                  </Box>
                </Box>
              </Grid>
              <Hidden smDown>
                <Grid item xs={12} md={3} />
              </Hidden>
              <Grid item xs={12} md={5}>
                <Box mb={4} mt={4}>
                  <SectionContent text={'About-P4'} danger />
                </Box>
              </Grid>
            </Grid> */}
            <Grid container className={classes.simpleFeatureContainer}>
              <Grid item xs={12} md={5}>
                <Box className={classes.paddingLeft4}>
                  <Hidden mdUp>
                    <Box mt={6} />
                  </Hidden>
                  <SectionTitle title={'About-H51'} />
                  <SectionContent text={'About-P51'} danger />
                  <Box mt={4} mb={6} className={classes.buttonContainer}>
                    <Button
                      size="medium"
                      variant="contained"
                      color="primary"
                      onClick={boardHandler}
                      className={classes.getStartedButton}
                    >
                      {t('About-CTA-51')}
                    </Button>
                  </Box>
                </Box>
              </Grid>
              <Hidden smDown>
                <Grid item xs={12} md={2} />
              </Hidden>
              <Grid item xs={12} md={5}>
                <Hidden mdUp>
                  <Box mt={6} />
                </Hidden>
                <SectionTitle title={'About-H52'} />
                <SectionContent text={'About-P52'} danger />
                <Box mt={4} mb={6} className={classes.buttonContainer}>
                  <Button
                    size="medium"
                    variant="contained"
                    color="primary"
                    onClick={mgmtHandler}
                    className={classes.getStartedButton}
                  >
                    {t('About-CTA-52')}
                  </Button>
                </Box>
              </Grid>
            </Grid>
            <Grid container className={classes.simpleFeatureContainer}>
              <Box className={classes.paddingLeft3}>
                <MediaObject imagePlacement={'right'} image={img2} imageSize={'large'}>
                  <Hidden mdUp>
                    <Box mt={9} />
                  </Hidden>
                  <SectionTitle title="About-H6" />
                  <Box className={classes.subLogo}>
                    <Typography className={classes.subtext}>{t('About-H61')}</Typography>
                  </Box>
                  <Box mt={2}>
                    <AboutListItem content={'About-P61'} />
                  </Box>
                  <AboutListItem content={'About-P62'} />
                  <AboutListItem content={'About-P63'} />
                </MediaObject>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <SubSubFooter />
    </>
  )
}
