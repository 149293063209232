import { FC, default as React, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'

import {
  WebsiteTitle,
  SubSubFooter,
  HeroImage,
  FeatureElements,
  SectionTitle,
  SectionContent,
  FeatureBox,
} from '../Common'
import imgHero2x from '../../assets/images/img-partner-1@2x.png'

import icon1 from '../../assets/icons/img-partners-1.svg'
import icon2 from '../../assets/icons/img-partners-2.svg'
import icon3 from '../../assets/icons/img-partners-3.svg'
import icon4 from '../../assets/icons/img-partners-4.svg'
import icon5 from '../../assets/icons/img-partners-5.svg'
import icon7 from '../../assets/icons/img-partners-7.svg'
import icon8 from '../../assets/icons/img-partners-8.svg'

import { tagManagerHandler } from '../../utils'
import { Button } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  paddingBox: {
    padding: theme.spacing(2.5, 5),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1.5, 2),
    },
  },
  simpleFeatureContainer: {
    maxWidth: '1050px',
    margin: '0 auto',
    marginBottom: theme.spacing(6),
  },
  iconFeatureContainer: {
    maxWidth: '700px',
    margin: '0 auto',
    marginBottom: theme.spacing(6),
  },
  featureContainer: {
    maxWidth: '1100px',
    margin: '0 auto',
  },
  centerSection: {
    maxWidth: '700px',
    margin: 'auto',
    '& > p': {
      textAlign: 'center',
    },
    '& > div > h2': {
      textAlign: 'center',
    },
  },
  buttonContainer: {
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      width: '100%',
    },
  },
  getStartedButton: {
    fontWeight: 700,
    minWidth: 200,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}))

type NormalFeatureElement = { title: string; content: string }
type SimpleFeatureElement = { icon: string; content: string }
type FeatureElement = { icon: string; title: string; content: string }
type BoxFeatureElement =
  | { type: 'title' | 'subtitle' | 'section' | 'content'; text: string }
  | { type: 'link'; text: string; linkTarget: string }
  | { type: 'separator' }

const featuresA: SimpleFeatureElement[] = [
  {
    icon: icon7,
    content: 'Partnerships-P31',
  },
  {
    icon: icon4,
    content: 'Partnerships-P32',
  },
  {
    icon: icon5,
    content: 'Partnerships-P33',
  },
  {
    icon: icon8,
    content: 'Partnerships-P34',
  },
]

const featureB: FeatureElement[] = [
  {
    icon: icon1,
    title: 'Partnerships-H41',
    content: 'Partnerships-P41',
  },
  {
    icon: icon2,
    title: 'Partnerships-H42',
    content: 'Partnerships-P42',
  },
  {
    icon: icon3,
    title: 'Partnerships-H43',
    content: 'Partnerships-P43',
  },
]

const featureC: BoxFeatureElement[] = [
  {
    type: 'subtitle',
    text: 'Partnerships-H61',
  },
  {
    type: 'content',
    text: 'Partnerships-P61',
  },
  {
    type: 'separator',
  },
]

const featureD: BoxFeatureElement[] = [
  {
    type: 'subtitle',
    text: 'Partnerships-H62',
  },
  {
    type: 'content',
    text: 'Partnerships-P62',
  },
  {
    type: 'separator',
  },
]

const featureE: BoxFeatureElement[] = [
  {
    type: 'subtitle',
    text: 'Partnerships-H63',
  },
  {
    type: 'content',
    text: 'Partnerships-P63',
  },
  {
    type: 'separator',
  },
]

const featureF: BoxFeatureElement[] = [
  {
    type: 'subtitle',
    text: 'Partnerships-H64',
  },
  {
    type: 'content',
    text: 'Partnerships-P64',
  },
  {
    type: 'separator',
  },
]

const featureG: BoxFeatureElement[] = [
  {
    type: 'subtitle',
    text: 'Partnerships-H65',
  },
  {
    type: 'content',
    text: 'Partnerships-P65',
  },
  {
    type: 'separator',
  },
]

const featuresH: NormalFeatureElement[] = [
  {
    title: 'Partnerships-H71',
    content: 'Partnerships-P71',
  },
  {
    title: 'Partnerships-H72',
    content: 'Partnerships-P72',
  },
  {
    title: 'Partnerships-H73',
    content: 'Partnerships-P73',
  },
  {
    title: 'Partnerships-H74',
    content: 'Partnerships-P74',
  },
  {
    title: 'Partnerships-H75',
    content: 'Partnerships-P76',
  },
  {
    title: 'Partnerships-H76',
    content: 'Partnerships-P76',
  },
]

export const PartnershipsPage: FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  tagManagerHandler('PagePartnerships')

  const partnerHandler = useCallback(() => {
    window.location.href = 'https://dashboard.bivial.com/signup/partner'
  }, [])

  return (
    <>
      <Box className={classes.paddingBox}>
        <Container>
          <WebsiteTitle title={'Partnerships-H1'} subtitle={'Partnerships-P1'} danger />
          <HeroImage image={imgHero2x} />
          <Grid container>
            <Grid item xs={12}>
              <Box mb={6} mt={-9}>
                <Box className={classes.centerSection}>
                  <SectionTitle title={'Partnerships-H2'} />
                  <SectionContent text={'Partnerships-P2'} danger />
                  <Box mt={4} mb={6} className={classes.buttonContainer}>
                    <Button
                      size="medium"
                      variant="contained"
                      color="primary"
                      onClick={partnerHandler}
                      className={classes.getStartedButton}
                    >
                      {t('COMMON-CTA-GetStarted')}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} className={classes.simpleFeatureContainer}>
              <FeatureElements fourElements elements={featuresA} />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box mb={6}>
              <Box className={classes.centerSection}>
                <SectionTitle title={'Partnerships-H4'} />
                <SectionContent text={'Partnerships-P4'} danger />
              </Box>
            </Box>
          </Grid>
          <Grid container>
            <Grid item xs={12} className={classes.simpleFeatureContainer}>
              <FeatureElements alignCenter elements={featureB} />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box mb={6}>
              <Box className={classes.centerSection}>
                <SectionTitle title={'Partnerships-H6'} />
              </Box>
            </Box>
          </Grid>
          <Grid container>
            <Grid item xs={12} className={classes.simpleFeatureContainer}>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <Grid container>
                    <Grid item xs={12}>
                      <FeatureBox isBlackBg={true} elements={featureC} />
                    </Grid>
                    <Box mt={2}>
                      <Grid item xs={12}>
                        <FeatureBox isBlackBg={true} elements={featureD} />
                      </Grid>
                    </Box>
                    <Box mt={2}>
                      <Grid item xs={12}>
                        <FeatureBox isBlackBg={true} elements={featureE} />
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid container>
                    <Grid item xs={12}>
                      <FeatureBox isBlackBg={true} elements={featureF} />
                    </Grid>
                    <Box mt={2}>
                      <Grid item xs={12}>
                        <FeatureBox isBlackBg={true} elements={featureG} />
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid item xs={12}>
            <Box mb={6}>
              <Box className={classes.centerSection}>
                <SectionTitle title={'Partnerships-H7'} />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} className={classes.simpleFeatureContainer}>
            <FeatureElements elements={featuresH} />
          </Grid> */}
        </Container>
      </Box>
      <SubSubFooter />
    </>
  )
}
