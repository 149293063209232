const outgoingLocalAch = {
  type: 'outbound_local_ach',
  sticky_note: '*Restrictions may apply.',
  visible_columns: ['currency', 'region', 'clearing_system', 'speed'],
  hidden_columns: [],
  column_names: {
    currency: '16',
    region: '04',
    clearing_system: '03',
    speed: '13',
  },
  data: [
    {
      currency: 'AED',
      region: 'United Arab Emirates',
      clearing_system: 'UAEFTS',
      speed: 'Instant',
    },
    {
      currency: 'AOA',
      region: 'Angola',
      clearing_system: 'SPTR',
      speed: '',
    },
    {
      currency: 'ARS',
      region: 'Argentina',
      clearing_system: 'COELSA',
      speed: '',
    },
    {
      currency: 'AUD',
      region: 'Australia',
      clearing_system: 'BECS',
      speed: '',
    },
    {
      currency: 'BGN',
      region: 'Bulgaria',
      clearing_system: 'RINGS',
      speed: '',
    },
    {
      currency: 'BRL',
      region: 'Brazil',
      clearing_system: 'SITRAF, STR',
      speed: 'Instant',
    },
    {
      currency: 'BWP',
      region: 'Botswana',
      clearing_system: 'BISS',
      speed: '',
    },
    {
      currency: 'CHF',
      region: 'Switzerland',
      clearing_system: 'SIC',
      speed: '',
    },
    {
      currency: 'CNY',
      region: 'China (e-commerce)',
      clearing_system: 'CNAPS',
      speed: '',
    },
    {
      currency: 'CNY',
      region: 'China (non e-commerce)',
      clearing_system: 'CNAPS',
      speed: '',
    },
    {
      currency: 'COP',
      region: 'Colombia',
      clearing_system: 'CENIT',
      speed: '',
    },
    {
      currency: 'CRC',
      region: 'Costa Rica',
      clearing_system: 'SINPE',
      speed: 'Instant',
    },
    {
      currency: 'CZK',
      region: 'Czech Republic',
      clearing_system: 'CERTIS',
      speed: 'Instant',
    },
    {
      currency: 'DKK',
      region: 'Denmark',
      clearing_system: 'Kronos',
      speed: '',
    },
    {
      currency: 'EBCS',
      region: 'Egypt',
      clearing_system: 'ACH',
      speed: '',
    },
    {
      currency: 'GBP',
      region: 'United Kingdom',
      clearing_system: 'Faster Payments',
      speed: 'Instant',
    },
    {
      currency: 'GHS',
      region: 'Ghana',
      clearing_system: 'GIS',
      speed: '',
    },
    {
      currency: 'GNF',
      region: 'Guinea',
      clearing_system: 'SYGFC',
      speed: '',
    },
    {
      currency: 'HKD',
      region: 'Hong Kong',
      clearing_system: 'CHATS',
      speed: '',
    },
    {
      currency: 'HUF',
      region: 'Hungary',
      clearing_system: 'VIBER',
      speed: '',
    },
    {
      currency: 'IDR',
      region: 'Indonesia',
      clearing_system: 'BI-RTGS',
      speed: '',
    },
    {
      currency: 'ILS',
      region: 'Israel',
      clearing_system: 'Zahav',
      speed: 'Instant',
    },
    {
      currency: 'INR',
      region: 'India',
      clearing_system: 'NEFT/RTGS/IMPS',
      speed: 'Instant',
    },
    {
      currency: 'INR',
      region: 'India',
      clearing_system: '(RTP) UPI',
      speed: 'Instant',
    },
    {
      currency: 'JMD',
      region: 'Jamaica',
      clearing_system: 'JETS',
      speed: '',
    },
    {
      currency: 'JPY',
      region: 'Japan',
      clearing_system: 'Zengin System',
      speed: '',
    },
    {
      currency: 'KES',
      region: 'Kenya',
      clearing_system: 'PesaLink',
      speed: 'Instant',
    },
    {
      currency: 'LKR',
      region: 'Sri Lanka',
      clearing_system: 'LankaPay',
      speed: 'Instant',
    },
    {
      currency: 'LSL',
      region: 'Lesotho',
      clearing_system: 'SASwitch',
      speed: '',
    },
    {
      currency: 'MAD',
      region: 'Morocco',
      clearing_system: 'SIMT',
      speed: '',
    },
    {
      currency: 'MGA',
      region: 'Madagascar',
      clearing_system: 'TNRF',
      speed: '',
    },
    {
      currency: 'MWK',
      region: 'Malawi',
      clearing_system: 'NATS',
      speed: '',
    },
    {
      currency: 'MYR',
      region: 'Malaysia',
      clearing_system: 'RENTAS',
      speed: '',
    },
    {
      currency: 'NAD',
      region: 'Namibia',
      clearing_system: 'NISS',
      speed: '',
    },
    {
      currency: 'NGN',
      region: 'Nigeria',
      clearing_system: 'NIBSS/RTGS',
      speed: '',
    },
    {
      currency: 'NOK',
      region: 'Norway',
      clearing_system: 'NICS',
      speed: '',
    },
    {
      currency: 'NPR',
      region: 'Nepal',
      clearing_system: 'NCHL-IPS',
      speed: 'Instant',
    },
    {
      currency: 'NZD',
      region: 'New Zealand',
      clearing_system: 'ESAS',
      speed: '',
    },
    {
      currency: 'PEN',
      region: 'Peru',
      clearing_system: 'CCE',
      speed: '',
    },
    {
      currency: 'PHP',
      region: 'Philippines',
      clearing_system: 'PhilPaSS',
      speed: 'Instant',
    },
    {
      currency: 'PLN',
      region: 'Poland',
      clearing_system: 'Elixir/Express Elixir',
      speed: 'Instant',
    },
    {
      currency: 'PYG',
      region: 'Paraguay',
      clearing_system: 'SIPAP',
      speed: '',
    },
    {
      currency: 'RON',
      region: 'Romania',
      clearing_system: 'SENT',
      speed: '',
    },
    {
      currency: 'RWF',
      region: 'Rwanda',
      clearing_system: 'RPS',
      speed: '',
    },
    {
      currency: 'SEK',
      region: 'Sweden',
      clearing_system: 'RIX',
      speed: 'Instant',
    },
    {
      currency: 'SGD',
      region: 'Singapore',
      clearing_system: 'MEPS',
      speed: 'Instant',
    },
    {
      currency: 'TRY',
      region: 'Turkey',
      clearing_system: 'EFT',
      speed: '',
    },
    {
      currency: 'UAH',
      region: 'Ukraine',
      clearing_system: 'Instant Payment System',
      speed: 'Instant',
    },
    {
      currency: 'UGX',
      region: 'Uganda',
      clearing_system: 'EFT',
      speed: '',
    },
    {
      currency: 'USD',
      region: 'United States',
      clearing_system: 'ACH',
      speed: '',
    },
    {
      currency: 'VND',
      region: 'Vietnam',
      clearing_system: 'IBPS',
      speed: '',
    },
    {
      currency: 'XAF',
      region: 'Central African Countries',
      clearing_system: 'BEAC',
      speed: '',
    },
    {
      currency: 'XOF',
      region: 'West African Countries',
      clearing_system: 'BCEAO',
      speed: '',
    },
    {
      currency: 'ZMW',
      region: 'Zambia',
      clearing_system: 'ZIPSS',
      speed: '',
    },
    {
      currency: 'EUR',
      region: 'Austria',
      clearing_system: 'SCT Inst',
      speed: 'Instant',
    },
    {
      currency: 'EUR',
      region: 'Belgium',
      clearing_system: 'SCT Inst',
      speed: 'Instant',
    },
    {
      currency: 'EUR',
      region: 'Cyprus',
      clearing_system: 'SCT Inst',
      speed: 'Instant',
    },
    {
      currency: 'EUR',
      region: 'Estonia',
      clearing_system: 'SCT Inst',
      speed: 'Instant',
    },
    {
      currency: 'EUR',
      region: 'Finland',
      clearing_system: 'SCT Inst',
      speed: 'Instant',
    },
    {
      currency: 'EUR',
      region: 'France',
      clearing_system: 'SCT Inst',
      speed: 'Instant',
    },
    {
      currency: 'EUR',
      region: 'Germany',
      clearing_system: 'SCT Inst',
      speed: 'Instant',
    },
    {
      currency: 'EUR',
      region: 'Greece',
      clearing_system: 'SCT Inst',
      speed: 'Instant',
    },
    {
      currency: 'EUR',
      region: 'Ireland',
      clearing_system: 'SCT Inst',
      speed: 'Instant',
    },
    {
      currency: 'EUR',
      region: 'Italy',
      clearing_system: 'SCT Inst',
      speed: 'Instant',
    },
    {
      currency: 'EUR',
      region: 'Latvia',
      clearing_system: 'SCT Inst',
      speed: 'Instant',
    },
    {
      currency: 'EUR',
      region: 'Lithuania',
      clearing_system: 'SCT Inst',
      speed: 'Instant',
    },
    {
      currency: 'EUR',
      region: 'Luxembourg',
      clearing_system: 'SCT Inst',
      speed: 'Instant',
    },
    {
      currency: 'EUR',
      region: 'Malta',
      clearing_system: 'SCT Inst',
      speed: 'Instant',
    },
    {
      currency: 'EUR',
      region: 'Netherlands',
      clearing_system: 'SCT Inst',
      speed: 'Instant',
    },
    {
      currency: 'EUR',
      region: 'Portugal',
      clearing_system: 'SCT Inst',
      speed: 'Instant',
    },
    {
      currency: 'EUR',
      region: 'Slovakia',
      clearing_system: 'SCT Inst',
      speed: 'Instant',
    },
    {
      currency: 'EUR',
      region: 'Slovenia',
      clearing_system: 'SCT Inst',
      speed: 'Instant',
    },
    {
      currency: 'EUR',
      region: 'Spain',
      clearing_system: 'SCT Inst',
      speed: 'Instant',
    },
  ],
}

const exportedObject = outgoingLocalAch

export default exportedObject
